import React from 'react'
import './CreateAccount.css'
import ImgAsset from '../public'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { supabase } from '../supabaseClient'

export default function CreateAccount () {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const signUp = async () => {
      const { user, session, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            name: name,
          },
        },
      });
      console.log(user);
      console.log(session);
      console.log(error);
      if (!error) {
        alert("Check your E-Mail account and click the verification link.");
      } else {
        alert(error);
      }
    }

    async function signInWithGoogle() {
      const { user, session, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      })
    }

    return (
        <div className='CreateAccount_CreateAccount'>
            <div className='LoginForm'>
                <span className='Createanaccount'>Create an account</span>
                <span className='Employthepowerofspreadsheet'>Employ the power of spreadsheet </span>
                <div className='NameDiv'>
                    <input className='Grouptitle_1' placeholder="Name" value={name} onChange={e => setName(e.target.value)}></input>
                    <img className='Line_1' src = {ImgAsset.CreateAccount_Line_1} />
                </div>
                <div className='NameDiv_1'>
                    <input className='Grouptitle_2' type="email" placeholder="E-Mail" value={email} onChange={e => setEmail(e.target.value)}></input>
                    <img className='Line_1' src = {ImgAsset.CreateAccount_Line_2} />
                </div>
                <div className='NameDiv_2'>
                    <input
                        type="password"
                        className='Grouptitle_3'
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    ></input>
                    <img className='Line_1' src = {ImgAsset.CreateAccount_Line_3} />
                </div>
                <div className='TermsCheckbox'>
                    {/*<div className='Checkbox'/>*/}
                    <input type="checkbox" className="Checkbox" />
                    <span className='Grouptitle_4'>I agree to the terms & conditions</span>
                </div>
                <div className='ButtonAction' onClick={() => signUp()}>
                    <div className='user'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    </div>
                    <span className='ButtonLabel_1'>Create account</span>
                </div>
                <div className='LoginGoogle' onClick={() => signInWithGoogle()}>
                    <div className='google'>
                        <img className='Vector' src = {ImgAsset.CreateAccount_Vector} />
                        <img className='Vector_1' src = {ImgAsset.CreateAccount_Vector_1} />
                        <img className='Vector_2' src = {ImgAsset.CreateAccount_Vector_2} />
                        <img className='Vector_3' src = {ImgAsset.CreateAccount_Vector_3} />
                        <img className='Vector_4' src = {ImgAsset.CreateAccount_Vector_4} />
                    </div>
                    <span className='ButtonLabel'>Signup with Google</span>
                </div>
                <div className='Login'>
                    <span className='Grouptitle'>Have an account?&nbsp;<u><Link to='/'>Login</Link></u></span>
                </div>
            </div>
            {/*<span className='HeaderTitle'>Easyy</span>*/}
            <div className='Photo'/>
        </div>
    )
}
