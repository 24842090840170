import React from 'react'
import './CreateAccount_1.css'
import Sidebar from "./Menu"
import ImgAsset from '../public'
import { supabase, supabaseUrl } from '../supabaseClient'
import { useState, useEffect, useRef } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { IP } from '../config.js'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { getInstagramUsername, getFacebookUsername, getInstagramAccountId, getFacebookPages } from './facebookUtil'
import PushNotification from "./PushNotification"
import ErrorNotification from "./ErrorNotification"

export default function CreateAccount_1 ({session}) {
    const [avatarUrl, setAvatarUrl] = useState("");
    const [name, setName] = useState("");
    const [sharedName, setSharedName] = useState("");
    const [role, setRole] = useState("can view");
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [invitedNames, setInvitedNames] = useState([]);
    const [spreadsheet, setSpreadsheet] = useState("");
    const [clickedCopy, setClickedCopy] = useState(false);
    // Facebook
    const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
    const [instagramId, setInstagramId] = useState("");
    const [fbPageId, setFbPageId] = useState("");
    const [fbPageAccessToken, setFbPageAccessToken] = useState("");
    const [instagram, setInstagram] = useState("@username");
    const [facebook, setFacebook] = useState("@username");
    const [twitter, setTwitter] = useState("@username");
    const [twitterAuthToken, setTwitterAuthToken] = useState("");
    const [twitterAuthTokenSecret, setTwitterAuthTokenSecret] = useState("");
    // Cookie stuff
    const [dark, setDark] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    const [overflow, setOverflow] = useState(true);
    const itemRef = useRef();
    const itemRef2 = useRef();

    let navigate = useHistory();

    const updatedInvitedName = (index, field, val) => {
      setOverflow(true);
      let newArr = [...invitedNames];
      newArr[index][field] = val; //e.target.value;
      setInvitedNames(newArr);
    }

    const removeInvitedName = (index) => {
      setOverflow(true);
      let newArr = [...invitedNames];
      newArr.splice(index, 1);
      setInvitedNames(newArr);
    }

    function addInvitedName() {
      console.log(invitedNames);
      if (invitedNames.length >= 5) {
        return;
      }
      if (!sharedName) {
        return;
      }
      if (!sharedName.includes("@")) {
        return;
      }
 
      var newName = {
        "id": invitedNames.length,
        "name": sharedName,
        "role": role,
        "dropdown": false,
      }
 
      setInvitedNames(
        [...invitedNames, newName]
      );
    }

    const createSheet = async () => {
      var form = new FormData();
      form.append("user_id", session.user.id);
      form.append("jwt", session.access_token);
      form.append("instagram", instagram);
      form.append("facebook", facebook);
      form.append("twitter", twitter);
  
      return fetch(IP + 'createsheet', {
        method: 'POST',
        body: form,
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        console.log(resp);
        return resp.json().then((data) => {
          console.log(data)
          return data;
        });
      });
    }

    const copyLink = async () => {
      var data = await createSheet();
      var spreadsheet_url = data["spreadsheet_url"];
      setSpreadsheet(spreadsheet_url);
      setClickedCopy(true);
      navigator.clipboard.writeText(spreadsheet_url);
      setTimeout(() => {
        setClickedCopy(false);
      }, 2000);
    }

    const saveAccountGroup = async (e) => {
      e.preventDefault();
      if (loading) {
        return;
      }

      if (!name) {
        itemRef2.current.setDisplay(true);
        return;
      }

      setLoading(true);

      var spreadsheet_url = spreadsheet;
      if (!spreadsheet) {
        var data = await createSheet();
        spreadsheet_url = data["spreadsheet_url"];
        setSpreadsheet(spreadsheet_url);
      }

      var window_ = window;

      try {
        const update = {
          name: name,
          projectavatar: avatarUrl,
          created_at: new Date(),
          user: session.user.id,
          fb_accesstoken: facebookUserAccessToken,
          instagram_id: instagramId,
          fb_pageid: fbPageId,
          fb_pageaccesstoken: fbPageAccessToken,
          instagram: instagram,
          facebook: facebook,
          twitter: twitter,
          twitter_access_token: twitterAuthToken,
          twitter_access_token_secret: twitterAuthTokenSecret,
          sheet: spreadsheet_url,
        };
        let { data, error } = await supabase.from('accountgroups').insert(update, {"returning": "representation"});
        console.log(data);
        console.log(error);
        if (error) {
          throw error;
        }

        let resp = await supabase
          .from('accountgroups')
          .select(`*`)
          .eq('user', session.user.id)
          .order('id', { ascending: false })
          .limit(1);
        console.log(resp);

        var user_emails = [];
        for (var idx in invitedNames) {
          let r = await supabase.from('invitedusers').insert({
              "user_email": invitedNames[idx].name,
              "account_group_id": resp.data[0].id,
              "role": invitedNames[idx].role
          }, {"returning": "representation"});
          console.log(r);

          var email = invitedNames[idx].name;
          user_emails.push(email);
          // supabase.auth.inviteUserByEmail({ email });
        }

        var form = new FormData();
        form.append("user_emails", JSON.stringify(user_emails));
  
        return fetch(IP + 'invite', {
          method: 'POST',
          body: form,
          mode: "cors",
          credentials: "include",
        }).then((resp) => {
          console.log(resp);
        });

        if (spreadsheet_url) {
          window_.open(spreadsheet_url);
        }
      } catch (error) {
          console.log(error);
          console.log(error.message);
        alert(error.message)
        // alert("Error! Please fill in all inputs.")
      } finally {
        setLoading(false);
        navigate.push("/")
      }
    }

    const uploadAvatar = async (event) => {
      try {
        setUploading(true)

        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.')
        }

        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`

        let { error: uploadError } = await supabase.storage
          .from('projectavatars')
          .upload(filePath, file)

        if (uploadError) {
          throw uploadError
        }

        // onUpload(filePath)
        setAvatarUrl(supabaseUrl + "/storage/v1/object/public/projectavatars/" + filePath)
      } catch (error) {
        alert(error.message)
      } finally {
        setUploading(false)
      }
    }

    // TWITTER
    const logInToTwitter = () => {
      fetch(IP + 'twitter_auth', {
        method: 'GET',
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        console.log(resp);
        resp.json().then((ret) => {
          var interval = setInterval(() => {
            fetch(IP + 'twitter_auth_result/' + ret["token"], {
              method: 'GET',
              mode: "cors",
              credentials: "include",
            }).then((resp_) => {
              resp_.json().then((result) => {
                if ("twitter_access_token" in result) {
                  setTwitterAuthToken(result["twitter_access_token"]);
                  setTwitterAuthTokenSecret(result["twitter_access_token_secret"]);
                  setTwitter(result["username"]);
                  clearInterval(interval);
                }
              });
            });
          }, 1000);

          window.open(ret["url"], '_blank');
        })
      });
    }

    // FACEBOOK
    const logInToFB = () => {
      window.FB.login(
        (response) => {
          console.log(response);
          var fb_accesstoken = response.authResponse?.accessToken;
          setFacebookUserAccessToken(fb_accesstoken);

          getFacebookPages(fb_accesstoken).then((facebookPages) => {
            console.log(facebookPages);
            setFbPageId(facebookPages[0].id);
            setFbPageAccessToken(facebookPages[0].access_token);
            setFacebook(facebookPages[0].name);
            getInstagramAccountId(fb_accesstoken, facebookPages[0].id).then((instagramAccountId) => {
              console.log(instagramAccountId);
              setInstagramId(instagramAccountId);
              getInstagramUsername(fb_accesstoken, instagramAccountId).then((resp) => {
                  setInstagram(resp.username);
              });
            });
          });
        },
        {
          config_id: "865874287999646",
          // auth_type: "code"
          // scope: "instagram_basic,pages_show_list,instagram_content_publish,pages_manage_posts",
          // extras: {
          //     "setup":{
          //       "external_business_id":"<external_business_id>",
          //       "timezone":"America\/Los_Angeles",
          //       "currency":"USD",
          //       "business_vertical":"ECOMMERCE"
          //     },
          //     "business_config":{
          //       "business":{
          //          "name":"<business_name>"
          //       },
          //       "ig_cta": {
          //         "enabled": true,
          //         "cta_button_text": "Book Now",
          //         "cta_button_url": "https://partner-site.com/foo-business"
          //       }
          //     },
          //     "repeat":false
          // }
        }
      );
    };

    useEffect(() => {
      // window.FB.getLoginStatus((response) => {
      //   console.log(response);
      //   setFacebookUserAccessToken(response.authResponse?.accessToken);

      //   getFacebookPages().then((facebookPages) => {
      //       console.log(facebookPages);
      //       getInstagramAccountId(facebookPages[0].id).then((instagramAccountId) => {
      //           console.log(instagramAccountId);
      //           // getFacebookUsername(response.authResponse?.userID).then((resp) => {
      //               // setFacebook(resp.name);
      //           // });
      //           setFacebook(facebookPages[0].name);
      //           getInstagramUsername(instagramAccountId).then((resp) => {
      //               setInstagram(resp.username);
      //           });
      //           // createMediaObjectContainer(
      //           //     instagramAccountId,
      //           //     "https://raw.githubusercontent.com/xozero/walls/master/jordan.jpg",
      //           //     "testing\n#test"
      //           // ).then((id_) => {
      //           //     console.log(id_);
      //           //     publishMediaObjectContainer(instagramAccountId, id_);
      //           // });
      //       });
      //   })
      // });
    }, []);

    useEffect(() => {
      if (!("dark" in cookies)) {
        setCookie("dark", false, {
          "secure": false,
          "httpOnly": false
        });
      }
      setDark(cookies["dark"] === "true");
    })

    return (
        <div className={`CreateAccount_1_CreateAccount ${dark ? "dark" : ""}`} style={{backgroundImage: session.user.user_metadata.background_image ? `url('${session.user.user_metadata.background_image}')` : "url('./bg.png')", overflow: !overflow ? "hidden" : "auto"}} >
{ session.user.user_metadata.background_image ?
            <div className={`${dark ? "dark" : ""} backgroundOverlay`} />
: "" }
            <Sidebar session={session} />
            <PushNotification ref={el => itemRef.current = el} title={"REMOVE USER?"} desc={"Are you sure you want to remove this user?"} action={removeInvitedName} closeAction={updatedInvitedName} />

            <ErrorNotification ref={el => itemRef2.current = el} desc={"Name missing."} />

            <div className='Frame1'>
                <div className='Container'>
                    <div className='Header'>
                        <span className='HeaderTitle'>Create Account</span>
                    </div>
                    <div className='ProfileInfo'>
                        <div className='LoginForm2'>
                            <div className='ProfilePic'>
                                <div className='DivProfile'>
                                    <div className='ProfileDiv_1'>
                                        <label htmlFor="single">
                                        <div className="avatar image AddProfile">
{ avatarUrl ?
                                        <img
                                          src={avatarUrl}
                                          alt="Project avatar"
                                          className="avatar image AddProfile"
                                        />
:
                                        <img
                                          src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='0.3' strokeLinecap='round' strokeLinejoin='round' class='feather feather-plus'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E`}
                                          alt="Project avatar"
                                          style={{width: "70px", height: "70px"}}
                                        />
}
                                        </div>
                                        </label>
                                        <VisuallyHidden>
                                          <input
                                            type="file"
                                            id="single"
                                            accept="image/*"
                                            onChange={uploadAvatar}
                                            disabled={uploading}
                                          />
                                        </VisuallyHidden>
                                        <span className='Description'>Add picture</span>
                                    </div>
                                </div>
                            </div>
                            <div className='NameDiv'>
                                <div className='DivData'>
                                    <input className='Grouptitle' placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
                                    <div className='EditIcon'>
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='DivPlan'>
                            <div className='Text'>
                                <div className='Payment'>
                                    <div className='zap'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                                    </div>
                                    <span className='Title'>Free - Plan </span>
                                </div>
                                <span className='cardnumber'>Upgrade for more features.</span>
                                <span className='Expirydate'>Free plans only have limited possibilities</span>
                            </div>
                            <div className='Chooseplan'>
                                <div className='zap_1'>
                                    <img className='Vector_21' src = {ImgAsset.CreateAccount_1_Vector_21} />
                                </div>
                                <span className='Text_1'>Choose Plan</span>
                            </div>
                        </div>
                        <div className='DivPlan_1'>
                            <div className='Text_2'>
                                <div className='Payment_1'>
                                    <div className='users'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                    </div>
                                    <span className='Title_1'>Connect account</span>
                                </div>
                                <div className='InfoDiv'>
                                    <span className='InfoDiv_1'>Connect your social media accounts.</span>
                                </div>
                            </div>
                            <div className='SocialButtons'>
                                <div className='APISocialButton'>
                                    <div className='SocialButton_2' onClick={() => logInToTwitter()}>
                                        <div className='IconLabel_6'>
                                            <div className='twitter'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                            </div>
                                            <span className='ButtonLabel_5'>Twitter</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='Usernames'>
                                    <div className='SocialButton_5'>
                                        <div className='IconLabel_9'>
                                            <span className={`ButtonLabel_8 ${twitter !== '@username' ? 'highlighted' : ''}`}>{ twitter }</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='ConnectedStatus'>
                                    <div className='Connected_2'>
{ twitter !== '@username' ?
                                        <>
                                            <div className='check'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            </div>
                                            <span className='Description_3 green'>Connected</span>
                                        </>
:
                                        <>
                                            <div className='alertcircle_2'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                            </div>
                                            <span className='Description_3'>Not connected</span>
                                        </>
}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='DivPlan_2'>
                            <div className='Frame1_1'>
                                <div className='Text_3'>
                                    <div className='Payment_2'>
                                        <div className='globe'>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                                        </div>
                                        <span className='Title_2'>Share to your team</span>
                                    </div>
                                    <div className='InfoDiv_2'>
                                        <span className='InfoDiv_3'>Anyone with the link can access the sheet</span>
                                    </div>
                                </div>
                                <div className='Users Usersinvite'>
                                    <div className='Invite'>
                                        <div className='Inputfield'>
                                            <input type="email" className='Text_4' placeholder="Enter emails"  value={sharedName} onChange={e => setSharedName(e.target.value)}/>
                                            <div className='Frame2' onClick={() => setDropdown(!dropdown)}>
                                                <span className='Text_5'>{ role }</span>
                                                <div className={`chevrondown ${dropdown ? 'rotate' : ''}`}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                                </div>
                                            </div>

{ dropdown ?
                                            <div className='Dropdown'>
                                                <div className='Item1' onClick={() => {setRole("Admin"); setDropdown(false);}}>
                                                    <div className='Div_3'/>
                                                    <div className='Frame3_2'>
                                                        <div className='Labels_3'>
                                                            <span className='Title_8'>Admin</span>
                                                            <span className='description_1'>Can edit, copy and change permissions</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2' onClick={() => {setRole("can edit"); setDropdown(false);}}>
                                                    <div className='Div_4'/>
                                                    <div className='Frame3_3'>
                                                        <div className='Labels_4'>
                                                            <span className='Title_9'>Can edit</span>
                                                            <span className='description_2'>Can edit and copy</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2_1' onClick={() => {setRole("can view"); setDropdown(false);}}>
                                                    <div className='Div_5'/>
                                                    <div className='Frame3_4'>
                                                        <div className='Labels_5'>
                                                            <span className='Title_10'>Can view</span>
                                                            <span className='description_3'>Can view but not edit components</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
: "" }

                                        </div>
                                        <div className='CopyLinkButton' onClick={() => addInvitedName()}>
                                            <span className='Text_6'>Invite</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='Users'>
{ invitedNames.map((invitedName, idx) => (
                                    <div className='User' key={idx}>
                                        <div className='Frame1_2'>
                                            <div className='ProfileDiv_7'>
                                                <div className='DefaultProfilePic'>
                                                    <div className='Profilepic'/>
                                                    <span className='yy'>yy</span>
                                                </div>
                                            </div>
                                            <span className='title'>{ invitedName.name }</span>
                                        </div>
                                        <div className='Frame2_1' onClick={() => updatedInvitedName(idx, "dropdown", !invitedName.dropdown)}>
                                            <span className='Text_6'>{ invitedName.role }</span>
                                            <div className={`chevrondown_1 ${invitedName.dropdown ? 'rotate' : ''}`}>
                                                <div className='chevrondown'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                                </div>
                                            </div>
                                        </div>
{ invitedName.dropdown ?
                                            <div className='Dropdown DropdownInvite'>
                                                <div className='Item1' onClick={() => {
                                                    updatedInvitedName(idx, "dropdown", false);
                                                    updatedInvitedName(idx, "role", "Admin");
                                                }}>
                                                    <div className='Div_3'/>
                                                    <div className='Frame3_2'>
                                                        <div className='Labels_3'>
                                                            <span className='Title_8'>Admin</span>
                                                            <span className='description_1'>Can edit, copy and change permissions</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2' onClick={() => {
                                                    updatedInvitedName(idx, "dropdown", false);
                                                    updatedInvitedName(idx, "role", "can edit");
                                                }}>
                                                    <div className='Div_4'/>
                                                    <div className='Frame3_3'>
                                                        <div className='Labels_4'>
                                                            <span className='Title_9'>Can edit</span>
                                                            <span className='description_2'>Can edit and copy</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2_1' onClick={() => {
                                                    updatedInvitedName(idx, "dropdown", false);
                                                    updatedInvitedName(idx, "role", "can view");
                                                }}>
                                                    <div className='Div_5'/>
                                                    <div className='Frame3_4'>
                                                        <div className='Labels_5'>
                                                            <span className='Title_10'>Can view</span>
                                                            <span className='description_3'>Can view but not edit components</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                    <div className='Item3Remove' onClick={() => {itemRef.current.setDisplay(true);updatedInvitedName(idx, "dropdown", false);itemRef.current.setAction(idx)}}>

                                                    <div className='Div_6'/>
                                                    <div className='Frame3_5'>
                                                        <div className='Labels_6'>
                                                            <span className='Title_11'>Remove</span>
                                                            <span className='description_4'>Remove this user</span>
                                                        </div>
                                                        <div className='trash2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgba(219,13,13,1.00)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </div>
                                                        <div className='check_6'>
                                                            <img className='Vector_12' src = {ImgAsset.Assets_Vector_12} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
: "" }
                                    </div>
))}
                                </div>
                                <div className='CopyLink'>
                                    <span className='title'>Anyone with the link can access this sheet</span>
                                    <div className='CopyLinkButton_1' onClick={() => {copyLink()}}>
                                        <div className='Frame1_2'>
                                            <div className='link2'>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                                            </div>
                                            <span className='Text_7'>Copy link</span>
{ clickedCopy ?
                                            <span className='Text_7_1'>Copied!</span>
: "" }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='CreateButtons'>
                        <div className='Loaderlabel'>
        { loading ?
                            <div role="status">
                                <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#f2f2f2"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
        : "" }
                        </div>
                        <div className={`ButtonAction ${loading ? 'disabled' : ''}`} onClick={e => saveAccountGroup(e)}>
                            <div className='IconDiv_5'>
                                <div className='MenuItemIconDiv_4'/>
                                <div className='layers_2'>
                                    <img className='Vector_46' src = {ImgAsset.CreateAccount_1_Vector_46} />
                                    <img className='Vector_47' src = {ImgAsset.CreateAccount_1_Vector_47} />
                                    <img className='Vector_48' src = {ImgAsset.CreateAccount_1_Vector_48} />
                                </div>
                            </div>
                            <span className='ButtonLabel_9'>Create and open sheeet</span>
                        </div>
                        <span className='ButtonLabel_10' onClick={e => saveAccountGroup(e)}>Create and return to dashboard</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
