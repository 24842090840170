const getFacebookPages = (facebookUserAccessToken) => {
  return new Promise((resolve) => {
    window.FB.api(
      "me/accounts",
      { access_token: facebookUserAccessToken },
      (response) => {
        resolve(response.data);
      }
    );
  });
};

const getInstagramAccountId = (facebookUserAccessToken, facebookPageId) => {
  //facebookUserAccessToken = "EABwrNBVRi1cBAHRHtI7PZAd8uPwL7ywpPjWvCtcF8pBVkJadpzcuLFqaERPNpge9LwFJQvnLIiQoWF2nswlmhEvvok3PvftVdW7OeHK5jnicEZCtG8mD4Cl0kGtYW0gWC4TljtLZCpZAXhqrUJ9pvUryjgchgC4XZAxq588yVFRhzi7ZA1OqSShVoEZB3N8D1mmD4agJFqgcNc58jwIUu7rXC4bQjxwjYcZD";
  //facebookPageId = "109869541589306";

  return new Promise((resolve) => {
    window.FB.api(
      facebookPageId,
      {
        access_token: facebookUserAccessToken,
        fields: "instagram_business_account",
      },
      (response) => {
          console.log(response);
          console.log(facebookPageId);
          console.log(facebookUserAccessToken);
        resolve(response.instagram_business_account.id);
      }
    );
  });
};

const getFacebookUsername = (facebookUserAccessToken, fbUserId) => {
  return new Promise((resolve) => {
    window.FB.api(
      fbUserId,
      {
        access_token: facebookUserAccessToken
      },
      (response) => {
        console.log(response);
        resolve(response);
      }
    );
  });
};

const getInstagramUsername = (facebookUserAccessToken, instagramAccountId) => {
  return new Promise((resolve) => {
    window.FB.api(
      instagramAccountId,
      {
        access_token: facebookUserAccessToken,
        fields: "id,username"
      },
      (response) => {
        console.log(response);
        resolve(response);
      }
    );
  });
};

// const createMediaObjectContainer = (instagramAccountId, imageUrl, postCaption) => {
//   return new Promise((resolve) => {
//     window.FB.api(
//       `${instagramAccountId}/media`,
//       "POST",
//       {
//         access_token: facebookUserAccessToken,
//         image_url: imageUrl,
//         caption: postCaption,
//       },
//       (response) => {
//         console.log(response);
//         resolve(response.id);
//       }
//     );
//   });
// };

// const publishMediaObjectContainer = (
//   instagramAccountId,
//   mediaObjectContainerId
// ) => {
//   return new Promise((resolve) => {
//     window.FB.api(
//       `${instagramAccountId}/media_publish`,
//       "POST",
//       {
//         access_token: facebookUserAccessToken,
//         creation_id: mediaObjectContainerId,
//       },
//       (response) => {
//         resolve(response.id);
//       }
//     );
//   });
// };

export { getInstagramUsername, getFacebookUsername, getInstagramAccountId, getFacebookPages };
