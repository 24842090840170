import React from 'react'
import './HomeAddAccount.css'
import ImgAsset from '../public'
import { supabase, supabaseUrl } from '../supabaseClient'
import { useState, useEffect } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { IP } from '../config.js'

const HomeAddAccount = ({ session, closeFunc }) => {
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [name, setName] = useState("");
    const [sharedName, setSharedName] = useState("");
    const [role, setRole] = useState("Select..");
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [invitedNames, setInvitedNames] = useState([]);

    // const updateArrayField = (index, field, e) => {
    //   let newArr = [...invitedNames];
    //   newArr[index][field] = e.target.value;
    //   setInvitedNames(newArr);
    // }
    function addInvitedName() {
      console.log(invitedNames);
      if (invitedNames.length >= 5) {
        return;
      }
 
      var newName = {
        "id": invitedNames.length,
        "name": sharedName,
        "role": role,
      }
 
      setInvitedNames(
        [...invitedNames, newName]
      );
    }

    const createSheet = async () => {
      var form = new FormData();
      form.append("user_id", session.user.id);
      form.append("jwt", session.access_token);
  
      return fetch(IP + 'createsheet', {
        method: 'POST',
        body: form,
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        console.log(resp);
        return resp.json().then((data) => {
          console.log(data)
          return data;
        });
      });
    }

    const saveAccountGroup = async (e) => {
      e.preventDefault();
      if (loading) {
        return;
      }

      setLoading(true);

      var data = await createSheet();
      var spreadsheet_url = data["spreadsheet_url"];

      var window_ = window;

      try {
        const update = {
          name: name,
          projectavatar: avatarUrl,
          created_at: new Date(),
          user: session.user.id,
          sheet: spreadsheet_url,
        };
        let { data, error } = await supabase.from('accountgroups').insert(update, {"returning": "representation"});
        console.log(data);
        console.log(error);
        if (error) {
          throw error;
        }

        let resp = await supabase
          .from('accountgroups')
          .select(`*`)
          .eq('user', session.user.id)
          .order('id', { ascending: false })
          .limit(1);
        console.log(resp);

        for (var idx in invitedNames) {
          let r = await supabase.from('invitedusers').insert({
              "user_email": invitedNames[idx].name,
              "account_group_id": resp.data[0].id
          }, {"returning": "representation"});
          console.log(r);
        }

        // let { data, error } = await supabase.from('invitedusers').upsert(update)
        // if (error) {
        //   throw error
        // }
        // console.log(data);

        window_.open(spreadsheet_url);
      } catch (error) {
        // alert(error.message)
        alert("Error! Please fill in all inputs.")
      } finally {
        setLoading(false);
        closeFunc();
      }
    }

    const uploadAvatar = async (event) => {
      try {
        setUploading(true)

        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.')
        }

        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`

        let { error: uploadError } = await supabase.storage
          .from('projectavatars')
          .upload(filePath, file)

        if (uploadError) {
          throw uploadError
        }

        // onUpload(filePath)
        setAvatarUrl(supabaseUrl + "/storage/v1/object/public/projectavatars/" + filePath)
      } catch (error) {
        alert(error.message)
      } finally {
        setUploading(false)
      }
    }

    return (
        <div className='HomeAddAccount_HomeAddAccount'>
            <div className='GroupsDiv'>
                <div className='ProfileDiv'>
                    <label className='DivAddPhoto' htmlFor="single">
                        <div className='ProfileDiv_1'/>
                        <div className='ProfilePic'>
                          <img
                            src={avatarUrl ? avatarUrl : `https://place-hold.it/100x100`}
                            alt="Project avatar"
                            className="avatar image"
                          />
                        </div>
                        <div className='plus'>
                            <img alt="" className='Vector_4' src = {ImgAsset.HomeAddAccount_Vector_4} />
                            <img alt="" className='Vector_5' src = {ImgAsset.HomeAddAccount_Vector_5} />
                        </div>
                    </label>
                    <VisuallyHidden>
                      <input
                        type="file"
                        id="single"
                        accept="image/*"
                        onChange={uploadAvatar}
                        disabled={uploading}
                      />
                    </VisuallyHidden>
                    <span className='Description_3'>Add project picture</span>
                </div>
                <div className='NameDiv'>
                    <input className='Grouptitle_2' placeholder="Name" value={name} onChange={e => setName(e.target.value)}></input>
                    <img alt="" className='Line' src = {ImgAsset.HomeAddAccount_Line} />
                </div>
                <div className='ConnectAccounts'>
                    <div className='DivStatus'>
                        <span className='Description_4'>Click to connect  account</span>
                        <span className='Description_5'>Status</span>
                    </div>
                    <div className='ButtonsStatus'>
                        <div className='DivLeft'>
                            <div className='Button1'>
                                <div className='MenuItemDiv'/>
                                <span className='ButtonLabel_2'>Instagram</span>
                                <div className='IconDiv_3'>
                                    <div className='MenuItemIconDiv_2'/>
                                    <div className='InstagramIcon'>
                                        <div className='IconDiv_4'/>
                                        <div className='instagram'>
                                            <img alt="" className='Vector_6' src = {ImgAsset.HomeAddAccount_Vector_6} />
                                            <img alt="" className='Vector_7' src = {ImgAsset.HomeAddAccount_Vector_7} />
                                            <img alt="" className='Vector_8' src = {ImgAsset.HomeAddAccount_Vector_8} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='Button1_1'>
                                <div className='MenuItemDiv_1'/>
                                <span className='ButtonLabel_3'>Facebook</span>
                                <div className='IconDiv_5'>
                                    <div className='MenuItemIconDiv_3'/>
                                    <div className='FacebookIcon'>
                                        <div className='IconDiv_6'/>
                                        <div className='facebook'>
                                            <img alt="" className='Vector_9' src = {ImgAsset.HomeAddAccount_Vector_9} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='Button1_2'>
                                <div className='MenuItemDiv_2'/>
                                <span className='ButtonLabel_4'>Twitter</span>
                                <div className='IconDiv_7'>
                                    <div className='MenuItemIconDiv_4'/>
                                    <div className='TwitterIcon'>
                                        <div className='IconDiv_8'/>
                                        <div className='twitter'>
                                            <img alt="" className='Vector_10' src = {ImgAsset.HomeAddAccount_Vector_10} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='DivRight'>
                            <div className='Connected'>
                                <span className='Description_6'>Not connected</span>
                                <div className='alertcircle'>
                                    <img alt="" className='Vector_11' src = {ImgAsset.HomeAddAccount_Vector_11} />
                                    <img alt="" className='Vector_12' src = {ImgAsset.HomeAddAccount_Vector_12} />
                                    <img alt="" className='Vector_13' src = {ImgAsset.HomeAddAccount_Vector_13} />
                                </div>
                            </div>
                            <div className='Connected_1'>
                                <span className='Description_7'>Not connected</span>
                                <div className='alertcircle_1'>
                                    <img alt="" className='Vector_14' src = {ImgAsset.HomeAddAccount_Vector_14} />
                                    <img alt="" className='Vector_15' src = {ImgAsset.HomeAddAccount_Vector_15} />
                                    <img alt="" className='Vector_16' src = {ImgAsset.HomeAddAccount_Vector_16} />
                                </div>
                            </div>
                            <div className='Connected_2'>
                                <span className='Description_8'>Not connected</span>
                                <div className='alertcircle_2'>
                                    <img alt="" className='Vector_17' src = {ImgAsset.HomeAddAccount_Vector_17} />
                                    <img alt="" className='Vector_18' src = {ImgAsset.HomeAddAccount_Vector_18} />
                                    <img alt="" className='Vector_19' src = {ImgAsset.HomeAddAccount_Vector_19} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='UserInput'>
                    <div className='StatusTitles'>
                        <span className='Description'>Share with</span>
                        <span className='Description_1'>Role</span>
                    </div>
                    <div className='DivSharingInput'>
                        <img className='EmailField' src = {ImgAsset.HomeAddAccount_EmailField} />
                        <div className='DivData'>
                            <input className='Grouptitle' placeholder="E-Mail" value={sharedName} onChange={e => setSharedName(e.target.value)}></input>
                            <div className='DivRoles' onClick={() => setDropdown(!dropdown)}>
                                <span className='Grouptitle_1'>{ role }</span>
                                <div className='IconDiv_1'>
                                    <div className='MenuItemIconDiv_1'/>
                                    <div className='ChevronDown'>
                                        <div className='IconDiv_2'/>
                                        <div className='chevrondown'>
                                            <img alt="" className='Vector_3' src = {ImgAsset.HomeAddAccount_Vector_3} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Infotext'>
                        <span className='Description_2'>*Members you invite with an admin role can edit, publish to social networks and disconnect.</span>
                    </div>
{ dropdown ?
                    <div className='DropdownRoleSelect'>
                        <div className='BackgroundDropdown'/>
                        <span className='Grouptitle_3' onClick={() => {setRole("Viewer"); setDropdown(false);}}>Viewer</span>
                        <span className='Grouptitle_4' onClick={() => {setRole("Editor"); setDropdown(false);}}>Editor</span>
                        <span className='Grouptitle_5' onClick={() => {setRole("Admin"); setDropdown(false);}}>Admin</span>
                    </div>
: "" }
                    <div className='Savelabel' onClick={() => addInvitedName()}>
    { sharedName != '' ?
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
    : "" }
                    </div>
                </div>

                <div className='DivMembers'>
{ invitedNames.length > 0 ?
					<div className='DivTitles'>
						<span className='Description_3'>Name</span>
						<span className='Description_4'>Role</span>
					</div>
: "" }
{ invitedNames.map((invitedName, idx) => (
					<div className='UserCellDiv' key={idx}>
						<div className='DivLeft'>
							<div className='ProfilePicDiv'>
								<div className='ProfilePicDiv_1'/>
								<img className='ProfilePic' src = {ImgAsset.HomeSettings_ProfilePic} />
							</div>
							<div className='Name'>
                                {/*<span className='SaraJames'>Sara James</span>*/}
								<span className='email'>{ invitedName.name }</span>
							</div>
						</div>
						<div className='DivRight'>
							<span className='Description_5'>{ invitedName.role }</span>
							<div className='usercheck'>
								<img className='Vector_1' src = {ImgAsset.HomeSettings_Vector_1} />
								<img className='Vector_2' src = {ImgAsset.HomeSettings_Vector_2} />
								<img className='Vector_3' src = {ImgAsset.HomeSettings_Vector_3} />
							</div>
						</div>
					</div>
))}
				</div>

                <div className="ButtonActions">
                    <div className={`ButtonAction ${loading ? 'disabled' : ''}`} onClick={e => saveAccountGroup(e)}>
                        <div className='IconDiv'>
                            <div className='MenuItemIconDiv'/>
                            <div className='layers'>
                                <img alt="" className='Vector' src = {ImgAsset.HomeAddAccount_Vector} />
                                <img alt="" className='Vector_1' src = {ImgAsset.HomeAddAccount_Vector_1} />
                                <img alt="" className='Vector_2' src = {ImgAsset.HomeAddAccount_Vector_2} />
                            </div>
                        </div>
                        <span className='ButtonLabel'>Create and open sheet</span>
                    </div>
                    <div className='ButtonLabel_1' onClick={e => saveAccountGroup(e)}>Create and return to dashboard</div>
                    <div className='Loaderlabel'>
    { loading ?
                        <div role="status">
                            <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
    : "" }
                    </div>
                </div>
                <div className='Close' onClick={() => {closeFunc()}}>
                    <img alt="" className='Union' src = {ImgAsset.HomeAddAccount_Union} />
                </div>
            </div>
        </div>
    )
};
export default HomeAddAccount;
