import React from 'react'
import './AddWidget.css'
import { supabase, supabaseUrl } from '../supabaseClient'
import { useState, useEffect, useRef } from 'react'
import { Draggable } from "react-drag-reorder";
import PushNotification from "./PushNotification"

function useForceUpdate(){
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

const AddWidget = ({ session, closeFunc }) => {
    const [accountGroups, setAccountGroups] = useState([]);
    const [widgets, setWidgets] = useState([]);
    const [dropdownAccountsShown, setDropdownAccountsShown] = useState(false);
    const [dropdownTypeShown, setDropdownTypeShown] = useState(false);
    const [account, setAccount] = useState("Select account");
    const [accountId, setAccountId] = useState("");
    const [type, setType] = useState("Select widget type");

    const itemRef = useRef();
    const forceUpdate = useForceUpdate();

    function dragElement(elmnt, elmntDrag) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      elmntDrag.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement(e) {
        document.onmouseup = null;
        document.onmousemove = null;
        forceUpdate();
      }
    }

    const getAccountGroups = async () => {
      try {
        const { user } = session

        let { data, error, status } = await supabase
          .from('accountgroups')
          .select(`id, name, projectavatar`)
          .eq('user', user.id)

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          setAccountGroups(data);
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }
    };

    const getWidgets = async () => {
      try {
        const { user } = session

        let { data, error, status } = await supabase
          .from('widgets')
          .select(`*`)
          .eq('user_id', session.user.id)
          .order('idx', { ascending: true })

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          setWidgets(data);
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }
    };

    const saveWidget = async () => {
      if (account === "Select account" || accountId === "" || type === "Select widget type") {
        return;
      }

      const update = {
        user_id: session.user.id,
        accountgroup_id: accountId,
        type: type,
        idx: widgets.length,
        account: account,
      };
      let { data, error } = await supabase.from('widgets').insert(update, {"returning": "representation"});
      console.log(data);
      console.log(error);
      if (error) {
        throw error;
      }

      setDropdownTypeShown(false);
      setDropdownAccountsShown(false);
      closeFunc();
    };

    const updateWidget = async (widget) => {
      const update = {
        user_id: session.user.id,
        accountgroup_id: widget.accountgroup_id,
        type: widget.type,
        idx: widget.idx,
        account: widget.account,
      };
      let { data, error } = await supabase.from('widgets').update(update).eq('id', widget.id);
      console.log(data);
      console.log(error);
      if (error) {
        throw error;
      }
    };

    const removeWidget = async (index) => {
      console.log(index);
      let { data, error } = await supabase.from('widgets').delete().eq('id', widgets[index].id);
      console.log(data);
      console.log(error);
      if (error) {
        throw error;
      }
      let newArr = [...widgets];
      newArr.splice(index, 1);
      setWidgets(newArr);
      forceUpdate();
    }

    const getChangedPos = async (currentPos, newPos) => {
      console.log(currentPos, newPos);
      var widgets_ = widgets.slice();
      var b = widgets_[currentPos];
      widgets_[currentPos] = widgets_[newPos];
      widgets_[newPos] = b;
      widgets_[currentPos].idx = currentPos;
      widgets_[newPos].idx = newPos;
      setWidgets(widgets_);

      await updateWidget(widgets_[currentPos]);
      await updateWidget(widgets_[newPos]);

      console.log(widgets);
    };

    useEffect(() => {
      console.log(session);
      getAccountGroups();
      getWidgets();
    }, [session]);

    useEffect(() => {
      document.addEventListener('dragover', function(e) { e.preventDefault() })
      var elmnt = document.getElementById("Widget");
      var elmntDrag = document.getElementById("WidgetTitle");
      dragElement(elmnt, elmntDrag);
    }, []);

    // useEffect(() => {
    //   console.log(session);
    // }, []);

	return (
		<div className='AddWidget_AddWidget' id="Widget">
			<div className='AddWidgetBackground'>
				<div className='Title' id="WidgetTitle">
					<div className='Frame3'>
						<div className='plussquare'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
						</div>
						<div className='Labels'>
							<span className='Title_1'>Manage widgets</span>
							<span className='description'>Manage and add new widgets to your acount</span>
						</div>
					</div>
				</div>
<div className="">
{ widgets.length > 0 ?
<Draggable onPosChange={getChangedPos}>
{widgets.map((widget, idx) => (
				<div className='Existingwidget' key={idx}>
					<div className='Widgetinput'>
						<div className='Inputfield'>
							<div className='Frame2'>
								<span className='Text'>{widget.account}</span>
								<div className='chevrondown'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
							</div>
						</div>
						<div className='Inputfield_1'>
							<div className='Frame2_1'>
								<span className='Text_1'>{widget.type}</span>
								<div className='chevrondown_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
							</div>
						</div>
					</div>
					<div className='morevertical'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
					</div>
					<div className='delete' onClick={() => {itemRef.current.setAction(idx);itemRef.current.setDisplay(true);}}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
					</div>
                    <PushNotification ref={el => itemRef.current = el} title={"REMOVE WIDGET?"} desc={"Are you sure you want to remove this widget?"} action={removeWidget} closeAction={() => {getWidgets().then(() => forceUpdate())}} />
				</div>
))}
</Draggable>
    : ""}
</div>
				<div className='AddWidget_1'>
{ dropdownAccountsShown ?
			<div className='Dropdown_1'>
{accountGroups.map((accountGroup, idx) => (
				<div className='Item1Selected_1' key={idx} onClick={() => {setAccountId(accountGroup.id); setAccount("@" + accountGroup.name.replaceAll(" ", "").toLowerCase()); setDropdownAccountsShown(false)}}>
					<div className='Div_4'/>
					<div className='Frame3_4'>
						<div className='ProfileDiv'>
							<div className='ProfileDiv_1'/>
{ accountGroup.projectavatar ?
							<img className='ProfilePic' src={accountGroup.projectavatar} alt="" />
:
                            <div className='DefaultProfilePic' style={{width: "38px", height: "38px"}}>
                                <div className='Profilepic'/>
                                <span className='yy' style={{fontSize: "12px", left: "1px"}}>yy</span>
                            </div>
}
						</div>
						<div className='Labels_6'>
							<span className='Title_7'>{accountGroup.name}</span>
							<span className='description_5'>@{accountGroup.name.replaceAll(" ", "").toLowerCase()}</span>
						</div>
						<div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
						</div>
					</div>
				</div>
))}
			</div>
: ""}
{ dropdownTypeShown ?
			<div className='Dropdown'>
				<div className='Item1Selected' onClick={() => {setType("Reach"); setDropdownTypeShown(false)}}>
					<div className='Div_1'/>
					<div className='Frame3_1'>
						<div className='Labels_3'>
							<span className='Title_4'>Reach</span>
							<span className='description_2'>Overall reach generated in the last month</span>
						</div>
						<div className='check'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
						</div>
					</div>
				</div>
				<div className='Item2' onClick={() => {setType("New followers"); setDropdownTypeShown(false)}}>
					<div className='Div_2'/>
					<div className='Frame3_2'>
						<div className='Labels_4'>
							<span className='Title_5'>New followers</span>
							<span className='description_3'>New monthly followers</span>
						</div>
						<div className='check'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
						</div>
					</div>
				</div>
				<div className='Item2_1' onClick={() => {setType("Engagement"); setDropdownTypeShown(false)}}>
					<div className='Div_3'/>
					<div className='Frame3_3'>
						<div className='Labels_5'>
							<span className='Title_6'>Engagement</span>
							<span className='description_4'>Number of likes, shares, comments</span>
						</div>
						<div className='check'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
						</div>
					</div>
				</div>
			</div>
: ""}
					<div className='AddWidget_2'>
						<div className='plussquare_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
						</div>
						<div className='Labels_1'>
							<span className='Title_2'>Add Widget</span>
							<span className='description_1'>Add a new widget</span>
						</div>
					</div>
					<div className='Widgetinput_2'>
						<div className='Inputfield_4' onClick={() => {setDropdownAccountsShown(!dropdownAccountsShown); if (dropdownTypeShown) setDropdownTypeShown(false)}}>
							<div className='Frame2_4'>
								<span className='Text_4'>{ account }</span>
								<div className={`chevrondown_4 ${dropdownAccountsShown ? 'rotate' : ''}`}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
							</div>
						</div>
						<div className='Inputfield_5' onClick={() => {setDropdownTypeShown(!dropdownTypeShown); if (dropdownAccountsShown) setDropdownAccountsShown(false)}}>
							<div className='Frame2_5'>
								<span className='Text_5'>{ type }</span>
								<div className={`chevrondown_5 ${dropdownTypeShown ? 'rotate' : ''}`}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='Actionbuttons'>
					<div className='Labels_2' onClick={() => closeFunc()}>
						<span className='Title_3'>Cancel</span>
					</div>
					<div className='ButtonAction' onClick={() => saveWidget()}>
						<span className='ButtonLabel'>Add widget</span>
					</div>
				</div>
			</div>
		</div>
	)
};
export default AddWidget;
