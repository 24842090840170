import React from 'react'
import './PricingPlans.css'
import {Link} from 'react-router-dom'
import Menu from './Menu'

const Privacy = ({session}) => {
	return (
		<div className='PricingPlans_PricingPlans'>
            <Menu session={session} />
			<div className='Container'>
				<div className='' style={{width: "60%"}}>
					<span className='Subheadingdescription' style={{height: "auto"}}>
<pre style={{color: "black", whiteSpace: "pre-wrap"}}>
Website Privacy Policy - Tracking Systems
<br/><br/>
Use of Google Analytics
We use the web analysis service Google Analytics from Google LLC on our website. (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; "Google").
<br/><br/>
If you have your habitual residence in the European Economic Area or Switzerland, Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is the person responsible for your data. Google Ireland Limited is therefore the company affiliated with Google, which is responsible for the processing of your data and compliance with the applicable data protection laws.
<br/><br/>
Data processing serves the purpose of analyzing this website and its visitors. For this purpose, Google will use the information obtained on behalf of the operator of this website to evaluate your use of the website, to compile reports on website activity and to provide other services related to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.
<br/><br/>
Google Analytics uses cookies that enable an analysis of your use of the website. The information generated by the cookies about your use of this website is usually transferred to a Google server in the USA and stored there. IP anonymization is activated on this website.
<br/><br/>
As a result, Google will shorten your IP address beforehand within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. The full IP address is only transferred to a Google server in the USA and abbreviated there in exceptional cases.
<br/><br/>
Google has certified itself according to the US-EU data protection agreement “Privacy Shield” and thus committed to comply with the European data protection guidelines.
<br/><br/>
You can prevent Google from collecting the data generated by the cookie and relating to your use of the website (including your IP address) and from processing this data by Google by downloading the browser plug-in available under the following link and install [https://tools.google.com/dlpage/gaoptout?hl=de].
<br/><br/>
To prevent data collection and storage by Google Analytics across devices, you can set an opt-out cookie. Opt-out cookies prevent the future collection of your data when you visit this website. You must perform the opt-out on all systems and devices used for this to have a comprehensive effect. If you delete the opt-out cookie, requests will be sent to Google again.
<br/><br/>
If you click here, the opt-out cookie will be set: Deactivate Google Analytics.
<br/><br/>
You can find more information on terms of use and data protection at https://www.google.com/analytics/terms/de.html or https://www.google.de/intl/de/policies/ .
<br/><br/>
Use Google’s remarketing or "Similar Audiences" feature
<br/><br/>
We use the remarketing or "similar target group" function of Google LLC on our website (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; "Google").
<br/><br/>
If you have your habitual residence in the European Economic Area or Switzerland, Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is the person responsible for your data. Google Ireland Limited is therefore the company affiliated with Google, which is responsible for the processing of your data and compliance with the applicable data protection laws.
<br/><br/>

The application serves the purpose of analyzing visitor behavior and visitor interests.
<br/><br/>
Google uses cookies to carry out the analysis of website use, which forms the basis for the creation of interest-based advertisements. Cookies are used to record visits to the website and anonymized data on the use of the website. There is no storage of personal data of the visitors of the website. If you visit another website in the Google Display Network below, you will see advertisements that are very likely to take account of previously accessed product and information areas.
<br/><br/>
Your data may be transferred to the United States. Google has certified itself according to the US-EU data protection agreement “Privacy Shield” and thus committed to comply with the European data protection guidelines.
<br/><br/>
You can permanently deactivate the use of cookies by Google by following the link below and downloading and installing the plug-in provided there: https://support.google.com/ads/answer/7395996?hl=de
<br/><br/>
Alternatively, you can prevent the use of cookies by third parties by calling up the deactivation page of the Network Advertising Initiative at https://www.networkadvertising.org/choices/ and implementing the further information on opt-out mentioned there.
<br/><br/>
You can find more information on Google remarketing and the associated data protection declaration at: https://www.google.com/privacy/ads/
<br/><br/>

Use of Google Ads conversion tracking
We use the "Google Ads" online advertising program on our website and, in this context, conversion tracking (visit campaign evaluation). Google Conversion Tracking is an analysis service provided by Google LLC (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; "Google").
<br/><br/>
If you have your habitual residence in the European Economic Area or Switzerland, Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is the person responsible for your data. Google Ireland Limited is therefore the company affiliated with Google, which is responsible for the processing of your data and compliance with the applicable data protection laws.
<br/><br/>
If you click on an advertisement placed by Google, a cookie for the conversion tracking is placed on your computer. These cookies have a limited validity, do not contain any personal data and are therefore not used for personal identification. If you visit certain pages of our website and the cookie has not yet expired, Google and we can recognize that you clicked on the advertisement and were forwarded to this page. Every Google Ads customer receives a different cookie. There is therefore no possibility that cookies can be traced via the websites of Ads customers.
<br/><br/>
The information that is obtained using the conversion cookie is used to create conversion statistics. Here we find out the total number of users who clicked on one of our ads and were redirected to a page with a conversion tracking tag. However, we do not receive any information that can be used to personally identify users.
<br/><br/>
Your data may be transferred to the United States. Google has certified itself according to the US-EU data protection agreement "Privacy Shield" and thus committed to comply with the European data protection guidelines.
<br/><br/>
You can deactivate personalized advertising for you in the Google advertising settings. Instructions for this can be found at https://support.google.com/ads/answer/2662922?hl=de .
<br/><br/>
Alternatively, you can prevent the use of cookies by third parties by calling up the deactivation page of the Network Advertising Initiative at https://www.networkadvertising.org/choices/ and implementing the further information on opt-out mentioned there.
<br/><br/>
You will then not be included in the conversion tracking statistics.
<br/><br/>
You can find more information and Google's privacy policy at: https://www.google.de/policies/privacy/
<br/><br/>
Use of Facebook Remarketing
We use the remarketing function "Custom Audiences" from Facebook Inc. (1601 S. California Ave, Palo Alto, CA 94304, USA; "Facebook") on our website.
<br/><br/>
The application serves the purpose of targeting visitors to the website with interest-based advertising on the social network Facebook.
<br/><br/>
For this purpose, the remarketing tag from Facebook was implemented on the website. This tag connects directly to the Facebook servers when you visit the website. This tells the Facebook server which of our pages you have visited. Facebook assigns this information to your personal Facebook user account. When you visit the social network Facebook, you will then be shown personalized, interest-related Facebook ads.
<br/><br/>
Your data may be transferred to the United States. Facebook has certified itself according to the US-EU data protection agreement "Privacy Shield" and thus committed to comply with the European data protection guidelines.
<br/><br/>
You can deactivate the remarketing function "Custom Audiences" here.
<br/><br/>
You can find more information on the collection and use of data by Facebook, your rights in this regard and options for protecting your privacy in Facebook's data protection information at https://www.facebook.com/about/privacy/ .
<br/><br/>
Use of Google AdSense
We use the Google LLC AdSense function on our website (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; "Google").
<br/><br/>
If you have your habitual residence in the European Economic Area or Switzerland, Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is the person responsible for your data. Google Ireland Limited is therefore the company affiliated with Google, which is responsible for the processing of your data and compliance with the applicable data protection laws.
<br/><br/>
The data processing serves the purpose of renting advertising space on the website and targeting visitors to the website with interest-based advertising.
<br/><br/>
This function is used to display personalized, interest-based advertising ads from the Google Display Network to visitors to the provider's website. Google uses cookies that enable an analysis of your use of the website.
<br/><br/>
The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there. Google may transfer this information to third parties if this is required by law or if third parties process this data on behalf of Google. Under no circumstances will Google link your IP address with other Google data. Google has certified itself according to the US-EU data protection agreement “Privacy Shield” and thus committed to comply with the European data protection guidelines.
<br/><br/>
You can permanently deactivate the use of cookies by Google by following the link below and downloading and installing the plug-in provided there: https://support.google.com/ads/answer/7395996?hl=de .
<br/><br/>
Alternatively, you can prevent the use of cookies by third parties by calling up the deactivation page of the Network Advertising Initiative at https://www.networkadvertising.org/choices/ and implementing the further information on opt-out mentioned there.
<br/><br/>
You can find more information and Google's privacy policy at: https://www.google.com/policies/technologies/ads/ , https://www.google.de/policies/privacy/ .
<br/><br/>

Plug-ins
<br/><br/>
Use of YouTube
We use the function for embedding YouTube videos from Google Ireland Limited on our website (Gordon House, Barrow Street, Dublin 4, Ireland; "YouTube"). YouTube is one with Google LLC (1600 Amphitheater Parkway, Mountain View, CA) 94043, USA; “Google”) affiliate.
<br/><br/>
The function displays videos stored on YouTube in an iFrame on the website. The option "Extended data protection mode" is activated. As a result, YouTube does not store any information about visitors to the website. Only when you watch a video, information about it is transmitted to YouTube and stored there. Your data may be transferred to the United States. Google has certified itself according to the US-EU data protection agreement “Privacy Shield” and thus committed to comply with the European data protection guidelines.
<br/><br/>
You can find more information on the collection and use of data by YouTube and Google, your rights in this regard and options for protecting your privacy in YouTube's data protection information (https://www.youtube.com/t/privacy) .
<br/><br/>
Use of Google Maps
We use the function for embedding Google Maps from Google LLC on our website (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; "Google").
<br/><br/>
If you have your habitual residence in the European Economic Area or Switzerland, Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is the person responsible for your data. Google Ireland Limited is therefore the company affiliated with Google, which is responsible for the processing of your data and compliance with the applicable data protection laws.
<br/><br/>
The function enables the visual display of geographic information and interactive maps. Google also collects, processes and uses data from visitors to the website when calling up the pages in which GoogleMaps maps are integrated.
<br/><br/>
Your data may also be transferred to the United States. Google has certified itself according to the US-EU data protection agreement “Privacy Shield” and thus committed to comply with the European data protection guidelines.
<br/><br/>
Processing takes place on the basis of Art. 6 Para. 1 lit. f GDPR from the legitimate interest in the needs-based and targeted design of our website.
<br/><br/>
You can find more information on the collection and use of data by Google in Google's data protection information at https://www.google.com/privacypolicy.html . There you also have the option of changing your settings in the data protection center so that you can manage and protect your data processed by Google.
<br/><br/>
Use of Google reCAPTCHA
We use the reCAPTCHA service from Google LLC on our website (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; "Google").
<br/><br/>
If you have your habitual residence in the European Economic Area or Switzerland, Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is the person responsible for your data. Google Ireland Limited is therefore the company affiliated with Google, which is responsible for the processing of your data and compliance with the applicable data protection laws. The purpose of the query is to differentiate between the input by a human being or by automated, mechanical processing. Your entry will be transmitted to Google and used there. In addition, the IP address and any other data required by Google for the reCAPTCHA service are transmitted to Google. This data is processed by Google within the European Union and, if necessary, also transmitted to the USA. Google has certified itself according to the US-EU data protection agreement “Privacy Shield” and thus committed to comply with the European data protection guidelines.
<br/><br/>
Processing takes place on the basis of Art. 6 Para. 1 lit. f GDPR for the legitimate interest of protecting our website against automated spying, misuse and SPAM.
<br/><br/>
Further information on Google reCAPTCHA and the associated data protection declaration can be found at: https://www.google.com/recaptcha/intro/android.html and https://www.google.com/privacy .
<br/><br/>
Data subject rights and storage period
Duration of storage
After completion of the contract, the data will be stored for the duration of the warranty period, then taking legal, especially tax and commercial retention periods into account and then deleted after the deadline, unless you have consented to further processing and use.
<br/><br/>
Rights of the data subject
If the legal requirements are met, you have the following rights under Art. 15 to 20 GDPR: Right to information, correction, deletion, restriction of processing, data portability.
<br/><br/>
In addition, according to Art. 21 Para. 1 GDPR you have a right to object to processing based on Art. 6 Para. 1 f GDPR and processing for the purpose of direct advertising.
<br/><br/>
Contact us on request. The contact details can be found in our imprint.
<br/><br/>
Right to lodge a complaint with the supervisory authority
<br/><br/>
According to Art. 77 GDPR, you have the right to complain to the supervisory authority if you believe that the processing of your personal data is not lawful.
<br/><br/>
Right to object
The personal data processing listed here is based on our legitimate interest in accordance with Art. 6 Para. 1 lit. f GDPR, you have the right to object to this processing at any time with future effect for reasons that arise from your particular situation.
<br/><br/>
After the objection has been made, the processing of the data concerned will be terminated, unless we can demonstrate compelling legitimate reasons for the processing that outweigh your interests, rights and freedoms, or if the processing serves to assert, exercise or defend legal claims.
<br/><br/>
If personal data processing takes place for the purposes of direct advertising, you can object to this processing at any time by notifying us. After the objection has been made, we stop processing the data concerned for the purpose of direct advertising.
<br/><br/>
Last update: 2022-09-12
<br/><br/>
</pre>

                    </span>
				</div>
			</div>
		</div>
	)
}
export default Privacy;
