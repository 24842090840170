import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RouterDOM from './Router';
import { CookiesProvider } from 'react-cookie';
import initFacebookSDK from "./facebookClient";


const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <CookiesProvider>
        <RouterDOM />
      </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

initFacebookSDK().then(() => {
  renderApp();
});
