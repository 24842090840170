import React from 'react'
import './AIGenerate.css'
import ImgAsset from '../public'
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { TagsInput } from "react-tag-input-component";
import { IP } from '../config.js'

const AIGenerate = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [account, setAccount] = useState();

    const [disabled, setDisabled] = useState(false);

    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState(["@elonmusk"]);
    const [selectedBlacklist, setSelectedBlacklist] = useState(["ugly and cheap"]);

    const [dropdown, setDropdown] = useState(false);
    const [time, setTime] = useState("GMT+0");

    function setSelectedKeywordsProcessed(tags) {
      const found = tags.find(tag => tag[0] != "#");
      if (found) {
        tags = tags.map((tag) => {return tag[0] != "#" ? "#" + tag : tag});
      }
      setSelectedKeywords(tags);
    }

    function setSelectedAccountsProcessed(tags) {
      const found = tags.find(tag => tag[0] != "@");
      if (found) {
        tags = tags.map((tag) => {return tag[0] != "@" ? "@" + tag : tag});
      }
      setSelectedAccounts(tags);
    }

    function reset() {
      setSelectedKeywords([]);
      setSelectedAccounts([]);
      setSelectedBlacklist([]);
    }

    function generate() {
      setDisabled(true);

      var form = new FormData();
      form.append("keywords", JSON.stringify(selectedKeywords));
      form.append("accounts", JSON.stringify(selectedAccounts));
      form.append("blacklist", JSON.stringify(selectedBlacklist));
      form.append("sheet_url", account['sheet']);

      return fetch(IP + 'genposts', {
        method: 'POST',
        body: form,
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        console.log(resp);
        return resp.json().then((data) => {
          console.log(data);
          setDisabled(false);
          setDisplay(false);
          reset();
          alert("Finished - go to the sheet to see your generated posts.");
          return data;
        });
      });
    }

    useImperativeHandle(ref, () => ({
        display, setDisplay, setAccount,
    }));

	return (
<>
{ display ?
		<div className='AIGenerate_AIGenerate'>
			<div className='BackgroundImage'>
				<div className='Title'>
					<div className='Div'/>
					<div className='Frame3'>
						<div className='AIIcon'>
							<div className='IconDiv'/>
							<div className='AI'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
							</div>
						</div>
						<div className='Labels'>
							<span className='Title_1'>AI post generator</span>
							<span className='description'>Add basic information to generate posts</span>
						</div>
						<div className='Connected' onClick={() => reset()}>
							<div className='refreshccw'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
							</div>
							<span className='Description'>Reset search</span>
						</div>
					</div>
				</div>
                {/*<div className='URL'>
					<div className='Titlediv'>
						<div className='Div_1'>
							<span className='Title_2'>Add URLs</span>
							<div className='plussqaure'>
								<img className='Vector_4' src = {ImgAsset.AIGenerate_Vector_4} />
								<img className='Vector_5' src = {ImgAsset.AIGenerate_Vector_5} />
							</div>
						</div>
						<span className='description_1'>Add URLs from competitors or similar companies/people</span>
					</div>
					<div className='Div_2'>
						<div className='Widgetinput'>
							<div className='Inputfield'>
								<div className='Frame2'>
									<span className='Text'>Add URL</span>
									<div className='xsymbol'>
										<img className='Vector_6' src = {ImgAsset.AIGenerate_Vector_6} />
										<img className='Vector_7' src = {ImgAsset.AIGenerate_Vector_7} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>*/}
				<div className='Tags'>
					<div className='Titlediv_1'>
						<span className='Title_3'>Keywords</span>
						<span className='description_2'>Add words that will be in the posts and provide a direction.</span>
					</div>
					<div className='Div_3'>
						<div className='Widgetinput_1'>
                            <TagsInput
                              value={selectedKeywords}
                              onChange={(tags) => setSelectedKeywordsProcessed(tags)}
                              name="keywords"
                              separators={["Enter", " "]}
                              placeHolder="enter keywords"
                            />
						</div>
					</div>
				</div>
				<div className='Accounts'>
					<div className='Titlediv_2'>
						<span className='Title_4'>Similar accounts</span>
						<span className='description_3'>Twitter handles that are similar and should serve as an inspiration.</span>
					</div>
					<div className='Div_11'>
						<div className='Widgetinput_2'>
                            <TagsInput
                              value={selectedAccounts}
                              onChange={(tags) => setSelectedAccountsProcessed(tags)}
                              name="accounts"
                              separators={["Enter", " "]}
                              placeHolder="enter accounts"
                            />
						</div>
					</div>
				</div>
				<div className='Blacklist'>
					<div className='Titlediv_3'>
						<span className='Title_5'>Blacklist</span>
						<span className='description_4'>Avoid using these keywords in your post generation</span>
					</div>
					<div className='Div_14'>
						<div className='Widgetinput_3'>
                            <TagsInput
                              value={selectedBlacklist}
                              onChange={setSelectedBlacklist}
                              name="blacklist"
                              placeHolder="enter blacklist"
                            />
						</div>
					</div>
				</div>
				<div className='Time'>
					<div className='Titlediv_4'>
						<span className='Title_6'>Posting times</span>
						<span className='description_5'>Select your time zone and we will prepare best posting times.</span>
					</div>
					<div className='Div_17'>
						<div className='Widgetinput_4'>
							<div className='Inputfield_4'>
								<div className='Frame2_1' onClick={() => setDropdown(!dropdown)}>
									<span className='Text_1'>Select time zone ({ time })</span>
                                    <div className={`chevrondown ${dropdown ? 'rotate' : ''}`}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                    </div>
								</div>
							</div>
						</div>
					</div>

{ dropdown ?
                                            <div className='SmallDropdown'>
{[...Array(13)].map((x, i) =>
                                                <div className='Item1' key={i} onClick={() => {setTime("GMT+" + i.toString()); setDropdown(false);}}>
                                                    <div className='Div_3'/>
                                                    <div className='Frame3_2'>
                                                        <div className='Labels_3'>
                                                            <span className='Title_8'>GMT+{ i }</span>
                                                        </div>
                                                    </div>
                                                </div>
)}
{[...Array(12)].map((x, i) =>
                                                <div className='Item1' key={-i} onClick={() => {setTime("GMT-" + (i+1).toString()); setDropdown(false);}}>
                                                    <div className='Div_3'/>
                                                    <div className='Frame3_2'>
                                                        <div className='Labels_3'>
                                                            <span className='Title_8'>GMT-{ i+1 }</span>
                                                        </div>
                                                    </div>
                                                </div>
)}
                                            </div>
: "" }

				</div>
				<div className='Posting'>
					<div className='Titlediv_5'>
						<span className='Title_7'>Daily posts</span>
						<span className='description_6'>How often do you want to post daily?</span>
					</div>
					<div className='Div_18'>
						<div className='Widgetinput_5'>
							<div className='Inputfield_5'>
								<div className='rows_5'>
									<div className='_10'>
										<div className='PillCell_11'>
											<div className='Div_19'>
												<span className='Label_11'>1</span>
											</div>
										</div>
										<span className='description_7'>FREE</span>
									</div>
									<div className='_20'>
										<div className='PillCell_12'>
											<div className='Div_20'>
												<span className='Label_12'>2</span>
											</div>
										</div>
										<span className='description_8'>Pro</span>
									</div>
									<div className='_50'>
										<div className='PillCell_13'>
											<div className='Div_21'>
												<span className='Label_13'>3</span>
											</div>
										</div>
										<span className='description_9'>Pro</span>
									</div>
									<div className='_100'>
										<div className='PillCell_14'>
											<div className='Div_22'>
												<span className='Label_14'>custom</span>
											</div>
										</div>
										<span className='description_10'>Type custom</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='Number'>
					<div className='Titlediv_4'>
						<span className='Title_6'>Generate</span>
						<span className='description_5'>Select number of posts you want to generate. You have 10 free / month.</span>
					</div>
					<div className='Div_17'>
						<div className='Widgetinput_4'>
							<div className='Inputfield_4'>
								<div className='rows_5'>
									<div className='_10'>
										<div className='PillCell_11'>
											<div className='Div_18'>
												<span className='Label_11'>10</span>
											</div>
										</div>
										<span className='description_6'>FREE</span>
									</div>
									<div className='_20'>
										<div className='PillCell_12'>
											<div className='Div_19'>
												<span className='Label_12'>20</span>
											</div>
										</div>
										<span className='description_7'>Pro</span>
									</div>
									<div className='_50'>
										<div className='PillCell_13'>
											<div className='Div_20'>
												<span className='Label_13'>50</span>
											</div>
										</div>
										<span className='description_8'>Pro</span>
									</div>
									<div className='_100'>
										<div className='PillCell_14'>
											<div className='Div_21'>
												<span className='Label_14'>100</span>
											</div>
										</div>
										<span className='description_9'>Pro</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='Actionbuttons'>
					<div className='Labels_1' onClick={() => {reset(); setDisplay(false);}}>
						<span className='Title_7'>Cancel</span>
					</div>
					<div className={`ButtonAction ${disabled ? 'disabled' : ''}`} onClick={() => generate()}>
						<span className='ButtonLabel'>Generate</span>
						<div className='AIIcon_1'>
							<div className='IconDiv_1'/>
							<div className='AI_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
: ""}
</>
	)
});

export default AIGenerate;
