import ImgAsset from '../public'
import './ErrorNotification.css'
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'

const ErrorNotification = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [action, setAction] = useState(null);

    useImperativeHandle(ref, () => ({
      display, setDisplay, setAction,
    }));

    useEffect(() => {
      // console.log(props);
    }, []);

    return (
<>
{ display ?
            <div className='ErrorNotification'>
                <div className='XButton_1'>
                    <div className='Ellipse5_1'/>
                    <div className='x_1'>
                        <img className='Vector_5' src = {ImgAsset.Assets_Vector_5} />
                        <img className='Vector_6' src = {ImgAsset.Assets_Vector_6} />
                    </div>
                </div>
                <div className='PushNotifications_1'>
                    <div className='Title_4'>
                        <div className='Div_3'/>
                        <div className='NotificationTitle_1'>
                            <div className='Labels_3'>
                                <span className='Title_5'>❌ ERROR</span>
                                <span className='description_1'>{ props.desc }</span>
                            </div>
                        </div>
                    </div>
                    <div className='ActionButton_1'>
                        <div className='Actionbutton_1' onClick={() => setDisplay(false)}>
                            <div className='Div_5'/>
                            <div className='Frame3_3'>
                                <div className='Labels_5'>
                                    <span className='Title_7'>OK</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
: ""}
</>
    );
});
export default ErrorNotification;
