import React from 'react'
import './PricingPlans.css'

const PageNotFound = () => {
	return (
		<div className='PricingPlans_PricingPlans'>
			<div className='Container'>
				<div className='' style={{width: "60%"}}>
					<span className='Subheadingdescription' style={{height: "auto"}}>
<pre style={{color: "black", whiteSpace: "pre-wrap"}}>
        404 - not found
</pre>
                    </span>
				</div>
			</div>
		</div>
	)
}
export default PageNotFound;
