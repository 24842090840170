import React from 'react'
import { useState, useEffect, useRef } from 'react'
import './Home.css'
import ImgAsset from '../public'
import {Link} from 'react-router-dom'
import HomeAddAccount from './HomeAddAccount'
import ChangeBackground from './ChangeBackground'
import HeaderAccountProfile from './HeaderAccountProfile'
import AccountGroups from './AccountGroups'
import Menu from './Menu'
import { supabase } from '../supabaseClient'
import { useCookies } from 'react-cookie'
import AIGenerate from './AIGenerate'

const Home = ({ session }) => {
    const [accountOverlay, setAccountOverlay] = useState(false)
    const [accountGroups, setAccountGroups] = useState([])
    const [dark, setDark] = useState(true);
    const [sort, setSort] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    const itemRef = useRef();
    const aiRef = useRef();
    const accGroups = useRef();

    const toggleDarkMode = () => {
      setDark(!dark);
      setCookie("dark", !dark, {
        "secure": false,
        "httpOnly": false
      });
    }

    async function logout() {
        await supabase.auth.signOut();
    };

    const openSheet = (url) => {
      window.open(url);
    }

    const getAccountGroups = async () => {
      try {
        const { user } = session

        let { data, error, status } = await supabase
          .from('accountgroups')
          .select(`name, projectavatar, sheet`)
          .eq('user', user.id)

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          setAccountGroups(data);
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }
    };

    function updateUser() {
      if (!session.user.user_metadata.name) {
        if (session.user.user_metadata.full_name) {
          supabase.auth.updateUser({
            data: {"name": session.user.user_metadata.full_name},
          });
        }
      }
    }

    function openAIGenerator(accountGroup) {
        console.log(accountGroup);
        aiRef.current.setDisplay(true);
        aiRef.current.setAccount(accountGroup);
    }

    useEffect(() => {
      console.log(cookies);
      if (!("dark" in cookies)) {
        setCookie("dark", false, {
          "secure": false,
          "httpOnly": false
        });
      }

      console.log(session);
      getAccountGroups();
      updateUser();
      setDark(cookies["dark"] === "true");
    }, []);

    return (
        <div className={`Home_Home ${dark ? "dark" : ""}`} style={{backgroundImage: session.user.user_metadata.background_image ? `url('${session.user.user_metadata.background_image}')` : "url('./bg.png')"}} >
{ session.user.user_metadata.background_image ?
            <div className={`${dark ? "dark" : ""} backgroundOverlay`} />
: "" }
{ accountOverlay ?
            <HomeAddAccount session={session} closeFunc={() => {setAccountOverlay(false)}} />
: '' }
            <AIGenerate ref={el => aiRef.current = el} />
            <Menu activeButton={"Sheets"} session={session} />
            <ChangeBackground session={session} ref={el => itemRef.current = el} />
            <div className='Frame7' onClick={() => toggleDarkMode()}>
                <div className='sun'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                </div>
                <div className='toggleright'>
{ dark ?
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-toggle-right"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg>
: 
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-toggle-left"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg>
}
                </div>
                <div className='moon'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
                </div>
            </div>
			<div className='Container'>
				<div className='Header'>
					<span className='HeaderTitle'>Dashboard</span>
                    {/*<div className='AccountProfile'>
						<div className='ProfileDiv'/>
						<div className='AccountData'>
							<div className='Maskgroup_1'>
								<div className='ProfileImage_1'/>
								<img className='unsplashplsF6obTgms_1' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />
							</div>
							<div className='AccountNameInfo'>
								<span className='Name'>Sara James</span>
								<span className='JobTitle'>Project Manager</span>
								<div className='IconDiv_4'>
									<div className='MenuItemIconDiv_4'/>
									<div className='IconChevronDown'>
										<img className='Vector_28' src = {ImgAsset.Home_Vector_28} />
									</div>
								</div>
							</div>
						</div>
					</div>*/}
				</div>
				<div className='Frame1'>
					<div className='DivMiddle'>
						<div className='DivAccountGroup'>
							<div className='DivAccountTitle'>
                                <div className='Frame1_1'>
                                    <span className='AccountGroup'>Account Group</span>
                                    <div className='Filtergroups' onClick={() => {setSort(!sort); accGroups.current.getAccountGroups(sort)}}>
                                        <div className='arrowdown'>
{ sort ?
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-down"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
:
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-up"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>
}
                                        </div>
{ sort ?
                                        <span className='Newsfirst'>Newest first</span>
:
                                        <span className='Newsfirst'>Oldest first</span>
}
                                    </div>
                                </div>
                                {/*<span className='AccountGroup'>Account Group</span>*/}
                                <Link to="/createaccount">
								<div className='Addaccounttextbutton'>
									<span className='Addanewaccount'>Add a new account</span>
									<div className='pluscircle'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
									</div>
								</div>
                                </Link>
							</div>
                            <AccountGroups session={session} ref={el => accGroups.current = el} openAIGenerator={openAIGenerator} />
						</div>
					</div>
					<div className='DivSide'>
						<div className='SideTitle'>
							<div className='TitleDiv'/>
							<span className='Notifications'>Notifications</span>
						</div>
                        <div className='SideCell'>
                            <div className='Info'>
                                <span className='Yourdayiscleared'>Your day is cleared 🎉</span>
                                <span className='Nonotifications'>No notifications</span>
                            </div>
                        </div>
					</div>
				</div>
			</div>
            <div className='BackgroundImageChange' onClick={() => itemRef.current.setDisplay(true)}>
                <span className='Changebackgroundimage'>Change background image</span>
                <div className='image'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                </div>
            </div>

        </div>
    )
};

export default Home;
