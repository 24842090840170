import ImgAsset from '../public'
import {Link} from 'react-router-dom'
import { supabase, supabaseUrl } from '../supabaseClient'
import './ChangeBackground.css'
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { IP } from '../config.js'
import VisuallyHidden from '@reach/visually-hidden'

const ChangeBackground = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [unsplashImage, setUnsplashImage] = useState("");
    const [photos, setPhotos] = useState([]);
    const [imgUrl, setImgUrl] = useState("");

    useImperativeHandle(ref, () => ({
      setDisplay,
    }));

    const resetImage = async () => {
      await supabase.auth.updateUser({
        data: {
          "background_image": "",
        }
      });
    }

    const selectPhoto = async (idx) => {
      setImgUrl(photos[idx]);

      await supabase.auth.updateUser({
        data: {
          "background_image": photos[idx],
        }
      });
    }

    const getImages = () => {
      var photos_ = fetch(IP + 'unsplash/' + unsplashImage + '/', {
        method: 'GET',
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        console.log(resp);
        return resp.json().then((data) => {
          console.log(data)
          setPhotos(data);
          return data;
        });
      });
    }

    const uploadPhoto = async (event) => {
      try {
        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.')
        }

        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`

        let { error: uploadError } = await supabase.storage
          .from('backgroundimages')
          .upload(filePath, file)

        if (uploadError) {
          throw uploadError
        }

        var url = supabaseUrl + "/storage/v1/object/public/backgroundimages/" + filePath;
        setImgUrl(url);
      } catch (error) {
        alert(error.message)
      } finally {
        await supabase.auth.updateUser({
          data: {
            "background_image": url,
          }
        });
      }
    }

    useEffect(() => {
      // console.log(props);
    }, []);

    return (
        <>
{ display ?
			<div className='BackgroundImage'>
				<div className='Title_16'>
					<div className='Div_10'/>
					<div className='Frame3_8'>
						<div className='image'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
						</div>
						<div className='Labels_10'>
							<span className='Title_17'>Change  Background</span>
							<span className='description_6'>Select an image or upload your own.</span>
						</div>
                        <div className='Connected' onClick={() => resetImage()}>
                            <div className='refreshccw'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
                            </div>
                            <span className='Description'>Reset image </span>
                        </div>
					</div>
				</div>
{ props.session.user.user_metadata.background_image || imgUrl ?
                <img className='MainImage' src={imgUrl || props.session.user.user_metadata.background_image} />
: "" }
				<div className='Unsplash'>
					<div className='Unsplashtitle'>
						<img className='UNsplash1' src = {ImgAsset.Assets_UNsplash1} />
						<div className='Labels_11'>
							<span className='Title_18'>Unsplash</span>
							<span className='description_7'>Select image from Unsplash</span>
						</div>
					</div>
					<div className='Search'>
                        <input className='Inputfield' placeholder="Type image name" value={unsplashImage} onChange={(e) => setUnsplashImage(e.target.value)}/>
                        {/*<div className='Inputfield'>
							<span className='Text'>Type in image name</span>
						</div>*/}
						<div className='CopyLinkButton' onClick={() => getImages()}>
							<span className='Text_1'>Search</span>
						</div>
					</div>
					<div className='Images'>
						<div className='Unplashline'>
{ photos.length > 0 ?
    <>
							<div className='Frame8'>
								<img className='Div_11' src={photos[0]} onClick={() => selectPhoto(0)}/>
								<img className='Div_12' src={photos[1]} onClick={() => selectPhoto(1)}/>
								<img className='Div_13' src={photos[2]} onClick={() => selectPhoto(2)}/>
							</div>
							<div className='Frame9'>
								<img className='Div_14' src={photos[3]} onClick={() => selectPhoto(3)}/>
								<img className='Div_15' src={photos[4]} onClick={() => selectPhoto(4)}/>
								<img className='Div_16' src={photos[5]} onClick={() => selectPhoto(5)}/>
							</div>
    </>
: "" }
						</div>
					</div>
				</div>
				<div className='UploadImage'>
					<div className='Div_17'/>
                    <label htmlFor="single">
					<div className='Frame3_9'>
						<div className='upload'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
						</div>
						<div className='Labels_12'>
							<span className='Title_19'>Upload image</span>
							<span className='description_8'>Upload your image from your files.</span>
						</div>
					</div>
                    </label>
                    <VisuallyHidden>
                      <input
                        type="file"
                        id="single"
                        accept="image/*"
                        onChange={uploadPhoto}
                      />
                    </VisuallyHidden>

				</div>
				<div className='Actionbuttons' onClick={() => setDisplay(false)}>
					<div className='Labels_13'>
						<span className='Title_20'>Cancel</span>
					</div>
					<div className='ButtonAction'>
						<span className='ButtonLabel'>Change image</span>
					</div>
				</div>
			</div>
: ""}
        </>
    );
});

export default ChangeBackground;
