import React from 'react'
import './PricingPlans.css'
import ImgAsset from '../public'
import {Link} from 'react-router-dom'
import Menu from './Menu'

const PricingPlans = ({session}) => {
	return (
		<div className='PricingPlans_PricingPlans'>
            <Menu session={session} />
			<div className='Container'>
				<div className='Header'>
					<span className='HeaderTitle'>Plans</span>
					<span className='Subheadingdescription'>description towards our pricing models.</span>
					<div className='AccountProfile'>
						<div className='ProfileDiv'/>
						<div className='AccountData'>
							<div className='Maskgroup_1'>
								<div className='ProfileImage_1'/>
								<img className='unsplashplsF6obTgms_1' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />
							</div>
							<div className='AccountNameInfo'>
								<span className='Name'>Sara James</span>
								<span className='JobTitle'>Project Manager</span>
								<div className='IconDiv_4'>
									<div className='MenuItemIconDiv_4'/>
									<div className='IconChevronDown'>
										<img className='Vector_28' src = {ImgAsset.PricingPlans_Vector_28} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='Frame'>
					<div className='DivSide'>
						<div className='PricingPlan'>
							<div className='Left'>
								<div className='Icon_2'>
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-feather"><path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path><line x1="16" y1="8" x2="2" y2="22"></line><line x1="17.5" y1="15" x2="9" y2="15"></line></svg>
								</div>
								<div className='TitleDescription'>
									<span className='HeaderTitle_1'>Basic</span>
									<span className='HeaderTitle_2'>Billed annually</span>
								</div>
							</div>
							<span className='Right'>Free</span>
						</div>
						<div className='Features'>
							<div className='Featuredescription'>
								<div className='Left_1'>
									<div className='check'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_1'>1 Account group</span>
							</div>
							<div className='Featuredescription_1'>
								<div className='Left_2'>
									<div className='check_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_2'>Instagram only</span>
							</div>
							<div className='Featuredescription_2'>
								<div className='Left_3'>
									<div className='check_2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_3'>10 posts / month</span>
							</div>
							<div className='Featuredescription_3'>
								<div className='Left_4'>
									<div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_4'>Post scheduling</span>
							</div>
							<div className='Featuredescription_4'>
								<div className='Left_5'>
									<div className='check_4'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_5'>Facebook, Twitter</span>
							</div>
							<div className='Featuredescription_5'>
								<div className='Left_6'>
									<div className='check_5'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_6'>Collaboration</span>
							</div>
						</div>
						<div className='ActionButtons'>
							<div className='ButtonAction'>
								<span className='ButtonLabel_3'>Sign-up for free</span>
							</div>
							<span className='ButtonLabel_4'>*changes may occur</span>
						</div>
					</div>
					<div className='DivSide_1'>
						<div className='PricingPlan_1'>
							<div className='Left_7'>
								<div className='zap'>
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
								</div>
								<div className='TitleDescription_1'>
									<span className='HeaderTitle_3'>Plus</span>
									<span className='HeaderTitle_4'>Billed annually</span>
								</div>
							</div>
							<span className='Right_7'>€49</span>
						</div>
						<div className='Features_1'>
							<div className='Featuredescription_6'>
								<div className='Left_8'>
									<div className='check_6'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_8'>3 Account groups</span>
							</div>
							<div className='Featuredescription_7'>
								<div className='Left_9'>
									<div className='check_7'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_9'>All social networks</span>
							</div>
							<div className='Featuredescription_8'>
								<div className='Left_10'>
									<div className='check_8'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_10'>Unlimited posts</span>
							</div>
							<div className='Featuredescription_9'>
								<div className='Left_11'>
									<div className='check_9'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_11'>Post scheduling</span>
							</div>
							<div className='Featuredescription_10'>
								<div className='Left_12'>
									<div className='check_10'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_12'>3 people team</span>
							</div>
							<div className='Featuredescription_11'>
								<div className='Left_13'>
									<div className='check_11'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_13'>Collaboration</span>
							</div>
						</div>
						<div className='ActionButtons_1'>
							<div className='ButtonAction_1'>
								<span className='ButtonLabel_5'>Purchase Plus Plan</span>
							</div>
							<span className='ButtonLabel_6'>*10 day money-back guarantee</span>
						</div>
					</div>
					<div className='DivSide_2'>
						<div className='PricingPlan_2'>
							<div className='Left_14'>
								<div className='codesandbox'>
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-codesandbox"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline><polyline points="7.5 19.79 7.5 14.6 3 12"></polyline><polyline points="21 12 16.5 14.6 16.5 19.79"></polyline><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
								</div>
								<div className='TitleDescription_2'>
									<span className='HeaderTitle_5'>Business</span>
									<span className='HeaderTitle_6'>Billed annually</span>
								</div>
							</div>
							<span className='Right_14'>€150</span>
						</div>
						<div className='Features_2'>
							<div className='Featuredescription_12'>
								<div className='Left_15'>
									<div className='check_12'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_15'>10 Account group</span>
							</div>
							<div className='Featuredescription_13'>
								<div className='Left_16'>
									<div className='check_13'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_16'>All social networks</span>
							</div>
							<div className='Featuredescription_14'>
								<div className='Left_17'>
									<div className='check_14'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_17'>Unlimited posts</span>
							</div>
							<div className='Featuredescription_15'>
								<div className='Left_18'>
									<div className='check_15'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_18'>Post scheduling</span>
							</div>
							<div className='Featuredescription_16'>
								<div className='Left_19'>
									<div className='check_16'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_19'>10 people team</span>
							</div>
							<div className='Featuredescription_17'>
								<div className='Left_20'>
									<div className='check_17'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
									</div>
								</div>
								<span className='Right_20'>Company account</span>
							</div>
						</div>
						<div className='ActionButtons_2'>
							<div className='ButtonAction_2'>
								<span className='ButtonLabel_7'>Purchase Business Plan</span>
							</div>
							<span className='ButtonLabel_8'>*10 day money-back guarantee</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default PricingPlans;
