import React from 'react'
import './Login.css'
import ImgAsset from '../public'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { supabase } from '../supabaseClient'
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

export default function Login () {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    let navigate = useHistory();

    const login = async () => {
      const { user, session, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      console.log(user);
      console.log(session);
      console.log(error);
      if (!error) {
        navigate.push("/");
      } else {
        alert(error);
      }
    }

    async function signInWithGoogle() {
      await supabase.auth.signInWithOAuth({
        provider: 'google',
      });
    }

    return (
        <div className='Login_Login'>
            <div className='LoginForm'>
                <span className='Login_2'>Login</span>
                <span className='Welcomeback'>Welcome back</span>
                <div className='DivEmail'>
                    <input className='Grouptitle_1 outline-none' placeholder="E-Mail" value={email} onChange={e => setEmail(e.target.value)}></input>
                    <img alt="" className='Line_1' src = {ImgAsset.Login_Line_1} />
                </div>
                <div className='DivPW'>
                    <input
                        type="password"
                        className='Grouptitle_2 outline-none'
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            login()
                          }
                        }}
                    ></input>
                    <img className='Line_2' src = {ImgAsset.Login_Line_2} alt="" />
                </div>
                <div className='ButtonAction' onClick={() => login()}>
                    <div className='arrowright'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </div>
                    <span className='ButtonLabel_1'>Login</span>
                </div>
                <div className='LoginGoogle' onClick={() => signInWithGoogle()}>
                    <div className='google'>
                        <img alt="" className='Vector' src = {ImgAsset.CreateAccount_Vector} />
                        <img alt="" className='Vector_1' src = {ImgAsset.CreateAccount_Vector_1} />
                        <img alt="" className='Vector_2' src = {ImgAsset.CreateAccount_Vector_2} />
                        <img alt="" className='Vector_3' src = {ImgAsset.CreateAccount_Vector_3} />
                        <img alt="" className='Vector_4' src = {ImgAsset.CreateAccount_Vector_4} />
                    </div>
                    <span className='ButtonLabel'>Login with Google</span>
                </div>
                <div className='Login_1'>
                    <span className='Grouptitle'>Don’t have an account?&nbsp;<u><Link to='/new'>Create Account</Link></u></span>
                </div>
            </div>
            <span className='HeaderTitle mask pseudo'>
              <div id="logo">
                <img src="https://app.easyy.co/easyy_logo.png" alt="easyy logo" />
              </div>
            </span>
            <div className='Photo'/>
            <CookieBanner
              message="This product uses essential cookies to work. By using this product you accept the use of cookies."
              onAcceptPreferences = {() => { }}
              onAcceptStatistics = {() => { }}
              onAcceptMarketing = {() => { }}
            />
        </div>
    )
}
