import React from 'react'
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import './AccountGroups.css'
import { supabase } from '../supabaseClient'
import { useHistory } from 'react-router-dom'

const AccountGroups = forwardRef((props, ref) => {
    const [accountGroups, setAccountGroups] = useState([]);

    let navigate = useHistory();

    useImperativeHandle(ref, () => ({
        getAccountGroups,
    }));

    const openSheet = (url) => {
      window.open(url);
    };

    const getAccountGroups = async (sort=false) => {
      const { user } = props.session
      var alldata = [];

      try {
        let { data, error, status } = await supabase
          .from('invitedusers')
          .select(`id, account_group_id, role, accountgroups(*)`)
          .eq('user_email', user.email)
          .order('created_at', { ascending: sort })
          //.eq('accountgroups.user', user.id)

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          for (var idx in data) {
              var accountgroup = data[idx]["accountgroups"];
              accountgroup["name"] += " [INVITED]";
              accountgroup["role"] = data[idx]["role"];
              alldata.push(accountgroup);
          }
          console.log(alldata);
          //setAccountGroups(alldata);
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }

      try {
        let { data, error, status } = await supabase
          .from('accountgroups')
          .select(`id, name, projectavatar, sheet, instagram, facebook, twitter, twitter_access_token, twitter_access_token_secret, fb_accesstoken, instagram_id`)
          .eq('user', user.id)
          .order('created_at', { ascending: sort })

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          alldata.push(...data);
          setAccountGroups(alldata);
          // alldata = data;
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }

    };

    const gotoAccountSettings = (accountGroup) => {
      navigate.push({
        pathname: '/accountsettings',
        state: { account: accountGroup }
      })
    }

    const openAIGenerator = (accountGroup) => {
      props.openAIGenerator(accountGroup);
    }

    useEffect(() => {
      console.log(props.session);
      getAccountGroups();
    }, [props.session]);

    return (
        <>
{accountGroups.length == 0 ?
                    <span style={{paddingLeft: '14px', color: 'black'}}>Pretty empty... Create your first account group!</span>
: ""}

{accountGroups.map((accountGroup, idx) => (
                <div className='GroupsDiv_1' key={idx}>
                    <div className='DivDataLeft_1'>
                        <span className='Grouptitle_1'>{ accountGroup.name }</span>
                        <span className='Description_11'>Free - Account</span>

                        <div className='ProfileDiv_7'>
                            <div className='ProfileDiv_8'/>
{ accountGroup.projectavatar ?
                            <img className='ProfilePic_7' src = { accountGroup.projectavatar } alt="" />
:
                            <div className='DefaultProfilePic'>
                                <div className='Profilepic'/>
                                <span className='yy'>yy</span>
                            </div>
}
                        </div>

                        <div className="connection">
{ accountGroup.twitter && accountGroup.twitter !== "@username" ?
                            <div className='TwitterIcon_1'>
                                <div className='twitter_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                </div>
                            </div>
: "" }
{ (accountGroup.twitter && accountGroup.twitter !== "@username") ?
                            <div className='ConnectionStatus'>
                                <span className='Description_12C'>Connected</span>
                                <div className='ActivityIcon_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                </div>
                            </div>
: "" }
{ !accountGroup.twitter || accountGroup.twitter === "@username" ?
                            <div className='TwitterIcon_1'>
                                <div className='twitter_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                </div>
                            </div>
: "" }
{ !accountGroup.twitter || accountGroup.twitter === "@username" ?
                            <div className='ConnectionStatus_1'>
                                <span className='Description_13C'>Disconnected</span>
                                <div className="ActivityIcon_2">
<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                </div>
                            </div>
: "" }
                        </div>
                    </div>
                    <div className='DivIconsRight_1'>
                        <div className='AIIcon' onClick={() => openAIGenerator(accountGroup)}>
                            <span className='Description_2'>Generate</span>
                            <div className='AIIcon_1'>
                                <div className='IconDiv_7'/>
                                <div className='AI'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                                </div>
                            </div>
                        </div>
                        <div className='SheetIcon_2' onClick={() => {openSheet(accountGroup.sheet)}}>
                            <span className='Description_14'>Edit Sheet</span>
                            <div className='SheetIcon_3'>
                                <div className='IconDiv_16'/>
                                <div className='layers_2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                </div>
                            </div>
                        </div>
{ !accountGroup['role'] || accountGroup['role'] == "Admin"  ?
                        <div className='SettingsIcon_2' onClick={() => gotoAccountSettings(accountGroup)}>
                            <span className='Description_15'>Settings</span>
                            <div className='SettingsIcon_3'>
                                <div className='IconDiv_17'/>
                                <div className='settings_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                </div>
                            </div>
                        </div>
: ""}
                    </div>
                </div>
            ))}
        </>
    )
});

export default AccountGroups;
