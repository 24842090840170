import ImgAsset from '../public'
import {Link} from 'react-router-dom'
import { supabase } from '../supabaseClient'
import './PushNotification.css'
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'

const PushNotification = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [action, setAction] = useState(null);

    useImperativeHandle(ref, () => ({
      display, setDisplay, setAction,
    }));

    useEffect(() => {
      // console.log(props);
    }, []);

    return (
<>
{ display ?
            <div className='PushNotification'>
                <div className='XButton' onClick={() => {props.closeAction(action, "dropdown", false); setDisplay(false);}}>
                    <div className='Ellipse5'/>
                    <div className='x'>
                        <img className='Vector' src = {ImgAsset.Assets_Vector} />
                        <img className='Vector_1' src = {ImgAsset.Assets_Vector_1} />
                    </div>
                </div>
                <div className='PushNotifications'>
                    <div className='Title'>
                        <div className='Div'/>
                        <div className='NotificationTitle'>
                            <div className='Labels'>
                                <span className='Title_1'>{ props.title }</span>
                                <span className='description'>{ props.desc }</span>
                            </div>
                        </div>
                    </div>
                    <div className='ActionButton'>
                        <div className='Secondarybutton' onClick={() => {props.closeAction(action, "dropdown", false);setDisplay(false);}}>
                            <div className='Div_1'/>
                            <div className='Frame3'>
                                <div className='Labels_1'>
                                    <span className='Title_2'>Cancel</span>
                                </div>
                                <div className='check_1'>
                                    <img className='Vector_3' src = {ImgAsset.Assets_Vector_3} />
                                </div>
                            </div>
                        </div>
                        <div className='Actionbutton' onClick={() => {setDisplay(false); props.action(action);}}>
                            <div className='Div_2'/>
                            <div className='Frame3_1'>
                                <div className='Labels_2'>
                                    <span className='Title_3'>Confirm</span>
                                </div>
                                <div className='check_2'>
                                    <img className='Vector_4' src = {ImgAsset.Assets_Vector_4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
: ""}
</>
    );
});
export default PushNotification;
