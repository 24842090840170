import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
// import HomePage from '../Components/index';
import Home from '../Components/Home';
import AllSheets from '../Components/AllSheets';
import HomeAddAccount from '../Components/HomeAddAccount';
import HomeSettings from '../Components/HomeSettings';
import Login from '../Components/Login';
import CreateAccount from '../Components/CreateAccount';
import CreateAccount1 from '../Components/CreateAccount_1';
import AccountSettings from '../Components/AccountSettings';
import Account from '../Account';
import Profile from '../Components/Profile';
import ProfileEdit from '../Components/Profileedit';
import PricingPlans from '../Components/PricingPlans';
import Privacy from '../Components/Privacy';
import Terms from '../Components/Terms';
import PageNotFound from '../Components/PageNotFound';
import AddWidget from '../Components/AddWidget';

const RouterDOM = () => {
    const [session, setSession] = useState(null)

    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
      })

      supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
      })
    }, [])

    return (
        <Router>
{!session ? (
            <Switch>
                <Route exact path="/"><Login /></Route>
                <Route exact path="/login"><Login /></Route>
                <Route exact path="/new"><CreateAccount /></Route>

                <Route exact path="/home"><Login /></Route>
                <Route exact path="/sheets"><Login /></Route>
                <Route exact path="/profile"><Login /></Route>
                <Route exact path="/editprofile"><Login /></Route>
                <Route exact path="/createaccount"><Login /></Route>
                <Route exact path="/accountsettings"><Login /></Route>
                <Route exact path="/pricing"><Login /></Route>
                <Route path="*"><PageNotFound /></Route>
            </Switch>
) : (
            <Switch>
                <Route exact path="/"><Home session={session} /></Route>
                <Route exact path="/login"><Login /></Route>
                <Route exact path="/new"><CreateAccount /></Route>
                <Route exact path="/home"><Home /></Route>
                <Route exact path="/sheets"><AllSheets session={session} /></Route>
                <Route exact path="/profile"><Profile session={session} /></Route>
                <Route exact path="/editprofile"><ProfileEdit session={session} /></Route>
                <Route exact path="/createaccount"><CreateAccount1 session={session} /></Route>
                <Route exact path="/accountsettings"><AccountSettings session={session} /></Route>
                <Route exact path="/pricing"><PricingPlans session={session} /></Route>
                <Route exact path="/privacy"><Privacy session={session} /></Route>
                <Route exact path="/terms"><Terms session={session} /></Route>

                <Route exact path="/addwidget"><AddWidget session={session} /></Route>

                <Route path="*"><PageNotFound /></Route>
            </Switch>
)}
        </Router>
    );
}
export default RouterDOM;
