import React from 'react'
import './Profileedit.css'
import ImgAsset from '../public'
import Menu from "./Menu"
import VisuallyHidden from '@reach/visually-hidden'
import { Link, useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { supabase, supabaseUrl } from '../supabaseClient'
import { useCookies } from 'react-cookie'

export default function Profileedit ({session}) {
    const [name, setName] = useState(session.user.user_metadata.name);
    const [email, setEmail] = useState(session.user.email);
    const [password, setPassword] = useState("•••••••••••••••••••••");
    const [username, setUsername] = useState(session.user.user_metadata.username || (session.user.user_metadata.name || "").replaceAll(" ", "").toLowerCase());
    const [about, setAbout] = useState(session.user.user_metadata.about || "");
    const [url, setUrl] = useState(session.user.user_metadata.url || "");
    const [profilePic, setProfilePic] = useState(session.user.user_metadata.profile_pic || "");

    const [dark, setDark] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    let navigate = useHistory();

    const saveProfile = async () => {
      if (username != session.user.user_metadata.username) {
        const {data, err} = await supabase.from('profiles').select("*").eq('username', username);
        console.log(data);
        console.log(err);
        if (data.length > 0) {
          // Username already exists.
          alert("Username already exists.");
          return;
        }
      }

      await supabase.from('profiles').upsert({
          "id": session.user.id,
          "username": username,
      });

      await supabase.auth.updateUser({
        data: {
          "name": name,
          "username": username,
          "about": about,
          "url": url,
          "profile_pic": profilePic,
        }
      });

      if (session.user.email !== email) {
        await supabase.auth.updateUser({email: email});
      }
      if (password !== "•••••••••••••••••••••") {
        await supabase.auth.updateUser({password: password});
      }

      navigate.push("/profile");
    }

    const uploadProfilePic = async (event) => {
      try {
        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.')
        }

        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`

        let { error: uploadError } = await supabase.storage
          .from('profilepics')
          .upload(filePath, file)

        if (uploadError) {
          throw uploadError
        }

        var url = supabaseUrl + "/storage/v1/object/public/profilepics/" + filePath;
        setProfilePic(url);
      } catch (error) {
        alert(error.message)
      } finally {
        await supabase.auth.updateUser({
          data: {
            "profile_pic": url,
          }
        });
      }
    }

    useEffect(() => {
      console.log(session);

      if (!("dark" in cookies)) {
        setCookie("dark", false, {
          "secure": false,
          "httpOnly": false
        });
      }
      setDark(cookies["dark"] === "true");
    }, [])

	return (
        <div className={`Profileedit_Profileedit ${dark ? "dark" : ""}`} style={{backgroundImage: session.user.user_metadata.background_image ? `url('${session.user.user_metadata.background_image}')` : "url('./bg.png')"}} >
{ session.user.user_metadata.background_image ?
            <div className={`${dark ? "dark" : ""} backgroundOverlay`} />
: "" }
            <Menu session={session} />
			<div className='Container'>
				<div className='Header'>
					<span className='HeaderTitle'>Profile</span>
                    {/*<div className='AccountProfile'>
						<div className='ProfileDiv'/>
						<div className='AccountData'>
							<div className='Maskgroup_1'>
								<div className='ProfileImage_1'/>
								<img className='unsplashplsF6obTgms_1' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />
							</div>
							<div className='AccountNameInfo'>
								<span className='Name'>Sara James</span>
								<span className='JobTitle'>Project Manager</span>
								<div className='IconDiv_2'>
									<div className='MenuItemIconDiv_2'/>
									<div className='IconChevronDown'>
										<img className='Vector_8' src = {ImgAsset.Profileedit_Vector_8} />
									</div>
								</div>
							</div>
						</div>
					</div>*/}
					<div className='SaveButtons'>
                        <Link to="/profile">
						<span className='ButtonLabel'>Cancel</span>
                        </Link>
						<div className='ButtonAction' onClick={() => saveProfile()}>
							<div className='save'>
								<img className='Vector_9' src = {ImgAsset.Profileedit_Vector_9} />
								<img className='Vector_10' src = {ImgAsset.Profileedit_Vector_10} />
								<img className='Vector_11' src = {ImgAsset.Profileedit_Vector_11} />
							</div>
							<span className='ButtonLabel_1'>Save and close</span>
						</div>
					</div>
				</div>
				<div className='ProfileInfo'>
					<div className='AccountInfo'>
						<div className='Payment'>
							<div className='user'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
							</div>
							<span className='Title'>Account information</span>
						</div>
						<div className='LoginForm2'>
							<div className='ProfilePic'>
								<div className='DivProfile'>
									<div className='Maskgroup_2'>
										<div className='Profile'/>

                                            <label htmlFor="single">
        {/*<img
                                              src={avatarUrl ? avatarUrl : `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 24 24' fill='none' stroke='curr    entColor' stroke-width='0.3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E    %3C/line%3E%3C/svg%3E`}
                                              alt="Project avatar"
                                              className="avatar image AddProfile"
                                            />*/}
{ profilePic ?
                                        <img className='ProfileImage_2' src = {profilePic} />
:
                                        <svg style={{zIndex: 10000, position: 'absolute'}} xmlns="http://www.w3.org/2000/svg" width="109" height="109" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
}

                                            </label>
                                            <VisuallyHidden>
                                              <input
                                                type="file"
                                                id="single"
                                                accept="image/*"
                                                onChange={uploadProfilePic}
                                              />
                                            </VisuallyHidden>


									</div>
									<span className='Description'>Change picture</span>
								</div>
							</div>
							<div className='NameDiv'>
								<span className='Description_1'>Name:</span>
								<div className='DivData'>
                                    <input className='Grouptitle' value={name} onChange={(e) => setName(e.target.value)}/>
									<div className='EditIcon'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
									</div>
								</div>
							</div>
						</div>
						<div className='EmailDiv'>
							<span className='Description_2'>Email:</span>
							<div className='DivData_1'>
                                <input className='Grouptitle_1' value={email} onChange={(e) => setEmail(e.target.value)}/>
								<div className='EditIcon_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
						<div className='PasswordDiv'>
							<span className='Description_3'>Password: Enter old password to change.</span>
							<div className='DivData_2'>
                                <input type="password" className='Grouptitle_2' value={password} onChange={(e) => setPassword(e.target.value)}/>
								<div className='EditIcon_2'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
							<span className='Description_3'>Enter new password.</span>
							<div className='DivData_2'>
                                <input type="password" className='Grouptitle_2' value={password} onChange={(e) => setPassword(e.target.value)}/>
								<div className='EditIcon_2'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
						<div className='UsernameDiv'>
							<span className='Description_4'>Username:</span>
							<div className='DivData_3'>
                                <input className='Grouptitle_3' value={username} onChange={(e) => setUsername(e.target.value)}/>
								<div className='EditIcon_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
						<div className='BioDiv'>
							<span className='Description_5'>Bio:</span>
							<div className='DivData_4'>
                                {/*<span className='Grouptitle_4'>Digital media manager and designer<br/>Ex-Googler<br/>Dog lover</span>*/}
                                <textarea className='Grouptitle_4' value={about} onChange={(e) => setAbout(e.target.value)} maxLength="250"/>
								<div className='EditIcon_4'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
						<div className='WebsiteDiv'>
							<span className='Description_6'>Link in bio</span>
							<div className='DivData_5'>
                                <input className='Grouptitle_5' value={url} onChange={(e) => setUrl(e.target.value)}/>
								<div className='EditIcon_5'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
					</div>
					<div className='PlanInfo'>
						<div className='Plan'>
							<div className='zap'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
							</div>
							<span className='Title_1'>Easyy Plan</span>
						</div>
						<div className='PaymentCN'>
							<span className='Description_7'>Current plan:</span>
                            <Link to="/pricing">
							<div className='DivData_6'>
								<span className='Grouptitle_6'>FREE - plan </span>
								<div className='EditIcon_6'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
							</Link>
						</div>
						<div className='DivPlan'>
							<div className='Text'>
								<div className='Payment_1'>
									<div className='zap_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
									</div>
									<span className='Title_2'>Upgrade to Easyy+ for €4,99/month</span>
								</div>
								<span className='cardnumber'>Upgrade for more features.</span>
								<span className='Expirydate'>Rates and discounts apply to annual plans</span>
							</div>
                            <a href="https://easyy.co/#pricing">
							<div className='Chooseplan'>
								<div className='zap_2'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
								</div>
								<span className='Text_1'>Buy €4.99</span>
							</div>
							</a>
						</div>
					</div>
                    {/*<div className='PaymentInfo'>
						<div className='Payment_2'>
							<div className='creditcard'>
								<img className='Vector_24' src = {ImgAsset.Profileedit_Vector_24} />
								<img className='Vector_25' src = {ImgAsset.Profileedit_Vector_25} />
							</div>
							<span className='Title_3'>Payment Information</span>
						</div>
						<div className='PaymentCN_1'>
							<span className='Description_8'>Credit card number</span>
							<div className='DivData_7'>
								<span className='Grouptitle_7'>*****1234</span>
								<div className='EditIcon_7'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
						<div className='PaymentED'>
							<span className='Description_9'>Expiry Date</span>
							<div className='DivData_8'>
								<span className='Grouptitle_8'>** 23</span>
								<div className='EditIcon_8'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
						<div className='PaymentCVC'>
							<span className='Description_10'>CVC</span>
							<div className='DivData_9'>
								<span className='Grouptitle_9'>***</span>
								<div className='EditIcon_9'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
								</div>
							</div>
						</div>
					</div>*/}
				</div>
			</div>
		</div>
	)
}
