import React from 'react'
import './Profile.css'
import ImgAsset from '../public'
import Menu from './Menu'
import { supabase } from '../supabaseClient'
import { Link, useHistory } from "react-router-dom"
import { useCookies } from 'react-cookie'
import { useEffect, useState } from 'react'

export default function Profile ({session}) {
    const [dark, setDark] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    let navigate = useHistory();

    async function logout() {
      supabase.auth.signOut().then(() => {
        navigate.push("/login");
      });
    };

    useEffect(() => {
      if (!("dark" in cookies)) {
        setCookie("dark", false, {
          "secure": false,
          "httpOnly": false
        });
      }
      setDark(cookies["dark"] === "true");
    }, [])

	return (
        <div className={`Profile_Profile ${dark ? "dark" : ""}`} style={{backgroundImage: session.user.user_metadata.background_image ? `url('${session.user.user_metadata.background_image}')` : "url('./bg.png')"}} >
{ session.user.user_metadata.background_image ?
            <div className={`${dark ? "dark" : ""} backgroundOverlay`} />
: "" }
            <Menu session={session} />
			<div className='Container'>
				<div className='Header'>
					<span className='HeaderTitle'>Profile</span>
                    {/*<div className='AccountProfile'>
						<div className='ProfileDiv'/>
						<div className='AccountData'>
							<div className='Maskgroup_1'>
								<div className='ProfileImage_1'/>
								<img className='unsplashplsF6obTgms_1' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />
							</div>
							<div className='AccountNameInfo'>
								<span className='Name'>Sara James</span>
								<span className='JobTitle'>Project Manager</span>
								<div className='IconDiv_4'>
									<div className='MenuItemIconDiv_4'/>
									<div className='IconChevronDown'>
										<img className='Vector_16' src = {ImgAsset.Profile_Vector_16} />
									</div>
								</div>
							</div>
						</div>
					</div>*/}
				</div>
				<div className='ProfileInfo'>
					<div className='LoginForm'>
						<div className='Frame1'>
							<div className='DivProfile'>
								<div className='Maskgroup_2'>
									<div className='Profile_1'/>
{ session.user.user_metadata.profile_pic ?
                                    <img className='ProfileImage_2' src = {session.user.user_metadata.profile_pic} />
:
                                    <svg style={{zIndex: 10000, position: 'absolute'}} xmlns="http://www.w3.org/2000/svg" width="109" height="109" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
}
								</div>
							</div>
						</div>
						<div className='DivData'>
							<span className='Name_1'>{ session.user.user_metadata.name }</span>
							<span className='Username'>@{ session.user.user_metadata.username || (session.user.user_metadata.name || "").replaceAll(" ", "").toLowerCase() }</span>
							<span className='BIO'>{ session.user.user_metadata.about || "Nothing here yet... Add something about yourself!" }</span>
							<span className='Link'><a className="Link" href={ session.user.user_metadata.url || "" }>{ session.user.user_metadata.url || "" }</a></span>

						</div>
                        <Link to="/editprofile">
						<div className='EditProfileButton'>
							<span className='Editaccountdata'>Edit account data</span>
							<div className='editPencil'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
							</div>
						</div>
                        </Link>
					</div>
                    {/*<div className='Stats'>
						<div className='StatsData'>
							<div className='TitleDiv'>
								<div className='eye'>
									<img className='Vector_17' src = {ImgAsset.Profile_Vector_17} />
									<img className='Vector_18' src = {ImgAsset.Profile_Vector_18} />
								</div>
								<span className='Description_1'>Reach</span>
								<div className='trendingup'>
									<img className='Vector_19' src = {ImgAsset.Profile_Vector_19} />
									<img className='Vector_20' src = {ImgAsset.Profile_Vector_20} />
								</div>
							</div>
							<span className='Description_2'>Total reach generated</span>
							<div className='MetaData'>
								<span className='Increase'>89%</span>
								<span className='Increase_1'>2022-08-14 > today</span>
							</div>
							<span className='Buttonlabel'>3,541,668</span>
						</div>
						<div className='StatsData_1'>
							<div className='TitleDiv_1'>
								<div className='users'>
									<img className='Vector_21' src = {ImgAsset.Profile_Vector_21} />
									<img className='Vector_22' src = {ImgAsset.Profile_Vector_22} />
									<img className='Vector_23' src = {ImgAsset.Profile_Vector_23} />
									<img className='Vector_24' src = {ImgAsset.Profile_Vector_24} />
								</div>
								<span className='Description_3'>Clients</span>
								<div className='trendingup_1'>
									<img className='Vector_25' src = {ImgAsset.Profile_Vector_25} />
									<img className='Vector_26' src = {ImgAsset.Profile_Vector_26} />
								</div>
							</div>
							<span className='Description_4'>Total clients managed</span>
							<div className='MetaData_1'>
								<span className='Increase_2'>-15%</span>
								<span className='Increase_3'>2022-08-14 > today</span>
							</div>
							<span className='Buttonlabel_1'>15</span>
						</div>
						<div className='StatsData_2'>
							<div className='TitleDiv_2'>
								<div className='heart'>
									<img className='Vector_27' src = {ImgAsset.Profile_Vector_27} />
								</div>
								<span className='Description_5'>Likes</span>
								<div className='trendingup_2'>
									<img className='Vector_28' src = {ImgAsset.Profile_Vector_28} />
									<img className='Vector_29' src = {ImgAsset.Profile_Vector_29} />
								</div>
							</div>
							<span className='Description_6'>Total likes obtained</span>
							<div className='MetaData_2'>
								<span className='Increase_4'>100%</span>
								<span className='Increase_5'>2022-08-14 > today</span>
							</div>
							<span className='Buttonlabel_2'>451,239</span>
						</div>
						<div className='StatsData_3'>
							<div className='TitleDiv_3'>
								<div className='award'>
									<img className='Vector_30' src = {ImgAsset.Profile_Vector_30} />
									<img className='Vector_31' src = {ImgAsset.Profile_Vector_31} />
								</div>
								<span className='Description_7'>Verified</span>
								<div className='trendingup_3'>
									<img className='Vector_32' src = {ImgAsset.Profile_Vector_32} />
									<img className='Vector_33' src = {ImgAsset.Profile_Vector_33} />
								</div>
							</div>
							<span className='Description_8'>Clients managed</span>
							<div className='ClientProfiles'>
								<div className='ProfileDiv_1'>
									<div className='ProfileDiv_2'/>
									<img className='ProfilePic' src = {ImgAsset.Assets_1_ProfilePic} />
								</div>
								<div className='ProfileDiv_3'>
									<div className='ProfileDiv_4'/>
									<img className='ProfilePic_1' src = {ImgAsset.Assets_1_ProfilePic} />
									<img className='ProfilePic_2' src = {ImgAsset.Analytics_ProfilePic_1} />
								</div>
							</div>
							<div className='MetaData_3'>
							</div>
						</div>
					</div>*/}
					<div className='DivPlan'>
						<div className='Text'>
							<div className='Payment'>
								<div className='user'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
								</div>
								<span className='Title'>Account data</span>
							</div>
							<div className='InfoDiv'>
								<span className='InfoDiv_1'>Full name: { session.user.user_metadata.name } </span>
								<span className='InfoDiv_2'>Username: @{ session.user.user_metadata.username || (session.user.user_metadata.name || "").replaceAll(" ", "").toLowerCase() }</span>
								<span className='InfoDiv_3'>Email: { session.user.email }</span>
								<span className='InfoDiv_4'>Password: ••••••••••</span>
							</div>
						</div>
					</div>
					<div className='DivPlan_1'>
						<div className='Text_1'>
							<div className='Payment_1'>
								<div className='zap'>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
								</div>
								<span className='Title_1'>Easyy Free</span>
							</div>
							<span className='cardnumber'>Renews: 31st of January 2023</span>
							<span className='Expirydate'>Cancel subscription</span>
						</div>
						<div className='EditProfileButton_1'>
                            <a href="https://easyy.co/#pricing">
							<span className='Changeplan'>Change plan</span>
                            </a>
							<div className='editPencil_1'>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
							</div>
						</div>
					</div>
                    <div className='Logout' onClick={() => logout()}>
                        <div className='logout'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        </div>
                        <div className='Labels'>
                            <span className='Title_7'>Log-out</span>
                            <span className='description'>Time for a break from work</span>
                        </div>
                    </div>

                    {/*<div className='DivPayment'>
						<div className='Text_2'>
							<div className='Payment_2'>
								<div className='creditcard'>
									<img className='Vector_39' src = {ImgAsset.Profile_Vector_39} />
									<img className='Vector_40' src = {ImgAsset.Profile_Vector_40} />
								</div>
								<span className='Title_2'>Payment Information</span>
							</div>
							<span className='cardnumber_1'>Card number: ******4343</span>
							<span className='Expirydate_1'>Expiration date: ** **</span>
						</div>
						<div className='EditProfileButton_2'>
							<span className='Edit'>Edit</span>
							<div className='editPencil_2'>
								<img className='Vector_41' src = {ImgAsset.Profile_Vector_41} />
							</div>
						</div>
					</div>
					<div className='DivProfileON'>
						<div className='Text_3'>
							<div className='TitleDiv_4'>
								<div className='eye_1'>
									<img className='Vector_42' src = {ImgAsset.Profile_Vector_42} />
									<img className='Vector_43' src = {ImgAsset.Profile_Vector_43} />
								</div>
								<span className='Title_3'>Profile Visibility</span>
							</div>
							<span className='Title_4'>When you make your profile public, others can add you to their projects as admins, editors or viewers.</span>
						</div>
						<div className='toggleright'>
							<img className='Vector_44' src = {ImgAsset.Profile_Vector_44} />
							<img className='Vector_45' src = {ImgAsset.Profile_Vector_45} />
						</div>
					</div>
					<div className='DivProfileON_1'>
						<div className='Text_4'>
							<div className='TitleDiv_5'>
								<div className='unlock'>
									<img className='Vector_46' src = {ImgAsset.Profile_Vector_46} />
									<img className='Vector_47' src = {ImgAsset.Profile_Vector_47} />
								</div>
								<span className='Title_5'>Profile Visibility</span>
							</div>
							<span className='Title_6'>When you make your account public, other clients can find you and see associated stats (reach, clients, verifications, etc...) on your public account.</span>
						</div>
						<div className='toggleright_1'>
							<img className='Vector_48' src = {ImgAsset.Profile_Vector_48} />
							<img className='Vector_49' src = {ImgAsset.Profile_Vector_49} />
						</div>
					</div>*/}
				</div>
			</div>
		</div>
	)
}
