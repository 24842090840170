import ImgAsset from '../public'
import {Link} from 'react-router-dom'
import { supabase } from '../supabaseClient'
import './Menu.css'
import { useEffect, useState, useRef } from 'react'
import { useCookies } from 'react-cookie'


function useForceUpdate(){
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

const Sidebar = (props) => {
    const type = useRef('vertical0');
    const forceUpdate = useForceUpdate();
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    async function logout() {
      await supabase.auth.signOut();
    };

    function dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement(e) {
        document.onmouseup = null;
        document.onmousemove = null;

        // var relX = (elmnt.offsetLeft + elmnt.offsetWidth) / window.screen.width;
        var relX = elmnt.offsetLeft / window.screen.width;
        var relY = elmnt.offsetTop / window.screen.height;
        if (type.current.startsWith('vertical')) {
          if (relX > 0.2 && relX < 0.8) {
            if (relY < 0.25) {
              elmnt.style.top = "10px";
              elmnt.style.bottom = "auto";
              elmnt.style.left = "calc(50% - 350px)";
              type.current = 'horizontal0';
              setCookie("menu", "horizontal0", {
                "secure": false,
                "httpOnly": false
              });
            } else {
              elmnt.style.top = "auto";
              elmnt.style.bottom = "10px";
              elmnt.style.left = "calc(50% - 350px)";
              type.current = 'horizontal1';
              setCookie("menu", "horizontal1", {
                "secure": false,
                "httpOnly": false
              });
            }
          } else {
            if (relX <= 0.2) {
              elmnt.style.top = "50%";
              elmnt.style.left = "33px";
              type.current = 'vertical0';
              setCookie("menu", "vertical0", {
                "secure": false,
                "httpOnly": false
              });
            } else {
              elmnt.style.top = "50%";
              elmnt.style.left = "auto";
              elmnt.style.right = "33px";
              type.current = 'vertical1';
              setCookie("menu", "vertical1", {
                "secure": false,
                "httpOnly": false
              });
            }
          }
        } else if (type.current.startsWith('horizontal')) {
          if (relX > 0.15 && relX < 0.4) {
            if (relY < 0.25) {
              elmnt.style.top = "10px";
              elmnt.style.bottom = "auto";
              elmnt.style.left = "calc(50% - 350px)";
              type.current = 'horizontal0';
              setCookie("menu", "horizontal0", {
                "secure": false,
                "httpOnly": false
              });
            } else {
              elmnt.style.top = "auto";
              elmnt.style.bottom = "10px";
              elmnt.style.left = "calc(50% - 350px)";
              type.current = 'horizontal1';
              setCookie("menu", "horizontal1", {
                "secure": false,
                "httpOnly": false
              });
            }
          } else {
            if (relX <= 0.2) {
              elmnt.style.top = "50%";
              elmnt.style.left = "33px";
              type.current = 'vertical0';
              setCookie("menu", "vertical0", {
                "secure": false,
                "httpOnly": false
              });
            } else {
              elmnt.style.top = "50%";
              elmnt.style.left = "auto";
              elmnt.style.right = "33px";
              type.current = 'vertical1';
              setCookie("menu", "vertical1", {
                "secure": false,
                "httpOnly": false
              });
            }
          }
        }
        forceUpdate();
      }
    }

    useEffect(() => {
      if (!("menu" in cookies)) {
        setCookie("menu", "vertical", {
          "secure": false,
          "httpOnly": false
        });
      } else {
        type.current = cookies["menu"];
      }
      forceUpdate();
      var elmnt = document.getElementById("Menu");
      dragElement(elmnt);

      if (type.current === 'vertical1') {
        elmnt.style.top = "50%";
        elmnt.style.left = "auto";
        elmnt.style.right = "33px";
      }
      if (type.current === 'horizontal1') {
        elmnt.style.top = "auto";
        elmnt.style.bottom = "10px";
        elmnt.style.left = "calc(50% - 350px)";
      }
    }, []);

    return (
        <>
			<div className={`${type.current.startsWith('vertical') ? 'Menu_1' : 'Menu'} ${type.current == 'vertical1' ? 'vertical1' : ''} `} id="Menu">
{ type.current.startsWith('vertical') ?
    <>
				<div className='MenuItems'>
                    <Link to="/profile">
					<div className='Maskgroup'>
						<div className='ProfileImage'/>
{ props.session.user.user_metadata.profile_pic ?
<>
                        {/*<img className='unsplashplsF6obTgms' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />*/}
                        <img className='unsplashplsF6obTgms' src = {props.session.user.user_metadata.profile_pic} />
</>
:
                        <svg style={{zIndex: 10000, position: 'absolute'}} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
}
					</div>
                    <div className='MenuLabel_3'>
                        <div className='Frame4_3'>
                            <span className='Title_7'>Profile</span>
                        </div>
                    </div>
                    </Link>
                    <Link to="/">
                    <div className={`Selected ${props.activeButton === 'Dashboard' ? 'active' : ''}`}>
						<div className='IconLabel'>
							<div className='IconDiv'>
								<div className='MenuItemIconDiv'/>
								<div className='Icon'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
								</div>
							</div>
						</div>
                        <div className='MenuLabel_3'>
                            <div className='Frame4_3'>
                                <span className='Title_7'>Dashboard</span>
                            </div>
                        </div>
					</div>
                    </Link>
                    <Link to="/sheets">
                    <div className={`Menu2 ${props.activeButton === 'Sheets' ? 'active' : ''}`}>
						<div className='IconDiv_1'>
							<div className='MenuItemIconDiv_1'/>
							<div className='layers'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
							</div>
						</div>
                        <div className='MenuLabel_3'>
                            <div className='Frame4_3'>
                                <span className='Title_7'>Sheets</span>
                            </div>
                        </div>
					</div>

                    </Link>
                    <div className={`Menu2_1 ${props.activeButton === 'Analytics' ? 'active' : ''}`} onClick={() => alert("Coming soon!")}>
						<div className='barchart'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
						</div>
                        <div className='MenuLabel_3'>
                            <div className='Frame4_3'>
                                <span className='Title_7'>Analytics</span>
                            </div>
                        </div>
					</div>
				</div>
				<div className='DragIcon'>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
				</div>
    </>
:
    <>
                <Link to="/profile">
                <div className='Maskgroup'>
                    <div className='ProfileImage'/>
{ props.session.user.user_metadata.profile_pic ?
                    <img className='unsplashplsF6obTgms' src = {props.session.user.user_metadata.profile_pic} />
:
                    <svg style={{zIndex: 10000, position: 'absolute'}} xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
}
                </div>
                </Link>
                <Link to="/">
                <div className={`Selected ${props.activeButton === 'Dashboard' ? 'active' : ''}`}>
					<div className='IconLabel'>
						<div className='IconDiv'>
							<div className='MenuItemIconDiv'/>
							<div className='Icon'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
							</div>
						</div>
						<span className='ButtonLabel'>Dashboard</span>
					</div>
				</div>
                </Link>
                <Link to="/sheets">
                <div className={`Menu2 ${props.activeButton === 'Sheets' ? 'active' : ''}`}>
					<div className='IconLabel_1'>
						<div className='IconDiv_1'>
							<div className='MenuItemIconDiv_1'/>
							<div className='layers'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
							</div>
						</div>
						<span className='ButtonLabel_1'>All Sheets</span>
					</div>
				</div>
                </Link>
                <div className={`Menu2_1 ${props.activeButton === 'Analytics' ? 'active' : ''}`} onClick={() => alert("Coming soon!")}>
					<div className='IconLabel_2'>
						<div className='barchart'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
						</div>
						<span className='ButtonLabel_2'>Analytics</span>
					</div>
				</div>
				<div className='DragIcon'>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
				</div>
    </>
}
			</div>
        </>
    );
}
export default Sidebar;
