import React from 'react'
import { useState, useEffect, useRef } from 'react'
import './Home.css'
import ImgAsset from '../public'
import {Link} from 'react-router-dom'
import HomeAddAccount from './HomeAddAccount'
import HeaderAccountProfile from './HeaderAccountProfile'
import AccountGroups from './AccountGroups'
import ChangeBackground from './ChangeBackground'
import Menu from './Menu'
import AddWidget from './AddWidget'
import AIGenerate from './AIGenerate'
import { supabase } from '../supabaseClient'
import { useCookies } from 'react-cookie'

import {getInstagramAccountId} from './facebookUtil'

const Home = ({ session }) => {
    const [accountOverlay, setAccountOverlay] = useState(false)
    const [accountGroups, setAccountGroups] = useState([])
    const [widgets, setWidgets] = useState([])
    const [widgetShown, setWidgetShown] = useState(false);
    const [dark, setDark] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    const itemRef = useRef();
    const aiRef = useRef();

    const toggleDarkMode = () => {
      setDark(!dark);
      setCookie("dark", !dark, {
        "secure": false,
        "httpOnly": false
      });
    }

    async function logout() {
        await supabase.auth.signOut();
    };

    const openSheet = (url) => {
      window.open(url);
    }

    const getAccountGroups = async () => {
      try {
        const { user } = session

        let { data, error, status } = await supabase
          .from('accountgroups')
          .select(`name, projectavatar, sheet`)
          .eq('user', user.id)

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          setAccountGroups(data);
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }
    };

    const getWidgets = async () => {
      try {
        const { user } = session

        let { data, error, status } = await supabase
          .from('widgets')
          .select(`user_id, accountgroups(*), type, idx, account`)
          .eq('user_id', session.user.id)
          .order('idx', { ascending: true })

        if (error && status !== 406) {
          throw error
        }

        if (data) {
          console.log(data);
          setWidgets(data);
        }
      } catch (error) {
        alert(error.message)
      } finally {
        // ..
      }
    };

    function updateUser() {
      if (!session.user.user_metadata.name) {
        if (session.user.user_metadata.full_name) {
          supabase.auth.updateUser({
            data: {"name": session.user.user_metadata.full_name},
          });
        }
      }
    }

    function openAIGenerator(accountGroup) {
        console.log(accountGroup);
        aiRef.current.setDisplay(true);
        aiRef.current.setAccount(accountGroup);
    }

    useEffect(() => {
      console.log(cookies);
      if (!("dark" in cookies)) {
        setCookie("dark", false, {
          "secure": false,
          "httpOnly": false
        });
      }

      getInstagramAccountId(0, 0);

      console.log(session);
      getAccountGroups();
      getWidgets();
      updateUser();
      setDark(cookies["dark"] === "true");

      console.log(session.user.user_metadata.background_image);
    }, []);

    return (
        <div className={`Home_Home ${dark ? "dark" : ""}`} style={{backgroundImage: session.user.user_metadata.background_image ? `url('${session.user.user_metadata.background_image}')` : "url('./bg.png')"}} >
{ session.user.user_metadata.background_image ?
            <div className={`${dark ? "dark" : ""} backgroundOverlay`} />
: "" }
{ accountOverlay ?
            <HomeAddAccount session={session} closeFunc={() => {setAccountOverlay(false)}} />
: '' }
{ widgetShown ?
            <AddWidget session={session} closeFunc={() => {setWidgetShown(false); getWidgets();}} />
: '' }
            <AIGenerate ref={el => aiRef.current = el} />
            <Menu activeButton={"Dashboard"} session={session} />
            <ChangeBackground session={session} ref={el => itemRef.current = el} />
            <div className='Frame7' onClick={() => toggleDarkMode()}>
                <div className='sun'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>
                </div>
                <div className='toggleright'>
{ dark ?
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-toggle-right"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg>
: 
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-toggle-left"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg>
}
                </div>
                <div className='moon'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
                </div>
            </div>
            <div className='Container'>
                <div className='Header'>
                    <span className='HeaderTitle'>Dashboard</span>
                    {/*<div className='AccountProfile'>
                        <div className='ProfileDiv'/>
                        <div className='AccountData'>
                            <div className='Maskgroup_1'>
                                <div className='ProfileImage_1'/>
                                <img className='unsplashplsF6obTgms_1' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />
                            </div>
                            <div className='AccountNameInfo'>
                                <span className='Name'>Sara James</span>
                                <span className='JobTitle'>Project Manager</span>
                                <div className='IconDiv_4'>
                                    <div className='MenuItemIconDiv_4'/>
                                    <div className='IconChevronDown'>
                                        <img className='Vector_28' src = {ImgAsset.Home_Vector_28} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
                <div className='Frame1'>
                    <div className='DivMiddle'>
						<div className='WidgetGroup'>
							<div className='DivAccountTitle'>
								<span className='Widgets'>Widgets</span>
								<div className='Addaccounttextbutton' onClick={() => setWidgetShown(true)}>
									<span className='Addwidget'>Add widget</span>
									<div className='pluscircle'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
									</div>
								</div>
							</div>
							<div className='WidgetsAnalytics'>
{widgets.length == 0 ?
                                <div className='AddWidget' onClick={() => setWidgetShown(true)}>
                                    <div className='AddGroupButton'/>
                                    <div className='IconDiv_5'>
                                        <div className='MenuItemIconDiv_5'/>
                                        <div className='AddGroupIcon'>
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                                        </div>
                                    </div>
                                    <span className='Buttonlabel'>Add widget</span>
                                    <span className='Description'>Create new widgets</span>
                                </div>
: ""}

{widgets.map((widget, idx) => (
                                <div className='StatsData_1' key={idx}>
                                    <div className='IconDiv_7'>
                                        <div className='MenuItemIconDiv_7'/>
                                        <div className='trendingup_1'>
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 6.07129L13.5 15.5713L8.5 10.5713L1 18.0713" stroke="#00643C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 6.07129H23V12.0713" stroke="#00643C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                                        </div>
                                    </div>
                                    <span className='Buttonlabel_2'>0</span>
                                    <span className='Description_4'>{ widget.type }</span>
{ widget.type === "Reach" ?
                                    <span className='Description_5'>Accounts reached</span>
: ""}
{ widget.type === "New followers" ?
                                    <span className='Description_5'>New monthly followers</span>
: ""}
{ widget.type === "Engagement" ?
                                    <span className='Description_5'>Likes, shares, comments</span>
: ""}
                                    <span className='Description_6'>{ widget.accountgroups.name }</span>
                                    <div className='ProgressBar_2'>
                                        <div className='ProgressBar_3'/>
                                        {/*<div className='ProgressBarFilled_1'/>*/}
                                    </div>
                                    <div className='ProfileDiv_3'>
                                        <div className='ProfileDiv_4'/>
{ widget.accountgroups.projectavatar ?
                                        <img className='ProfilePic_1' src = {widget.accountgroups.projectavatar} alt="" />
:
                                        <div className='DefaultProfilePic' style={{width: "30px", height: "30px"}}>
                                            <div className='Profilepic'/>
                                            <span className='yy' style={{fontSize: "12px", left: "1px"}}>yy</span>
                                        </div>
}
                                    </div>
                                </div>
))}

                                {/*<div className='StatsData'>
                                    <div className='AddGroupButton_1'/>
                                    <div className='IconDiv_6'>
                                        <div className='MenuItemIconDiv_6'/>
                                        <div className='trendingup'>
                                            <img className='Vector_32' src = {ImgAsset.Home_Vector_32} />
                                            <img className='Vector_33' src = {ImgAsset.Home_Vector_33} />
                                        </div>
                                    </div>
                                    <span className='Buttonlabel_1'>2022-09-14</span>
                                    <span className='Description_1'>Scheduled Until</span>
                                    <span className='Description_2'>Time to schedule content</span>
                                    <span className='Description_3'>Starbucks</span>
                                    <div className='ProgressBar'>
                                        <div className='ProgressBar_1'/>
                                        <div className='ProgressBarFilled'/>
                                    </div>
                                    <div className='ProfileDiv_1'>
                                        <div className='ProfileDiv_2'/>
                                        <img className='ProfilePic' src = {ImgAsset.Assets_1_ProfilePic} />
                                    </div>
                                </div>
                                <div className='StatsData_1'>
                                    <div className='AddGroupButton_2'/>
                                    <div className='IconDiv_7'>
                                        <div className='MenuItemIconDiv_7'/>
                                        <div className='trendingup_1'>
                                            <img className='Vector_34' src = {ImgAsset.Home_Vector_34} />
                                            <img className='Vector_35' src = {ImgAsset.Home_Vector_35} />
                                        </div>
                                    </div>
                                    <span className='Buttonlabel_2'>332,223</span>
                                    <span className='Description_4'>Reach</span>
                                    <span className='Description_5'>Accounts reached</span>
                                    <span className='Description_6'>Nike SB</span>
                                    <div className='ProgressBar_2'>
                                        <div className='ProgressBar_3'/>
                                        <div className='ProgressBarFilled_1'/>
                                    </div>
                                    <div className='ProfileDiv_3'>
                                        <div className='ProfileDiv_4'/>
                                        <img className='ProfilePic_1' src = {ImgAsset.Analytics_ProfilePic_1} />
                                    </div>
                                </div>*/}
							</div>
						</div>


                        <div className='DivAccountGroup'>
                            <div className='DivAccountTitle'>
                                <span className='AccountGroup'>Account Group</span>
                                <Link to="/createaccount">
                                <div className='Addaccounttextbutton'>
                                    <span className='Addanewaccount'>Add a new account</span>
                                    <div className='pluscircle'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                                    </div>
                                </div>
                                </Link>
                            </div>
                            <AccountGroups session={session} openAIGenerator={openAIGenerator} />
                        </div>
                    </div>
                    <div className='DivSide'>
                        <div className='AdDiv'>
                            <div className='AdDiv_1'/>
                            <div className='DivIllustrations'>
                                <div className='Illustration1'>
                                    <div className='Ellipse2'/>
                                    <div className='Ellipse3'/>
                                    <div className='Ellipse4'/>
                                </div>
                                <div className='Illustration1_1'>
                                    <div className='Ellipse2_1'/>
                                    <div className='Ellipse3_1'/>
                                    <div className='Ellipse4_1'/>
                                </div>
                            </div>
                            <div className='DivLeft'>
                                <div className='InfoText'>
                                    <span className='Textlabel'>Upgrade to Pro</span>
                                    <span className='Textlabel_1'>Save 2h daily for only €49.99/m </span>
                                </div>
                                <Link to="/pricing">
                                <div className='SmallButton'>
                                    <span className='ButtonText'>Upgrade now!</span>
                                </div>
                                </Link>
                            </div>
                        </div>
                        <div className='SideTitle'>
                            <div className='TitleDiv'/>
                            <span className='Notifications'>Notifications</span>
                        </div>
                        <div className='SideCell'>
                            <div className='Info'>
                                <span className='Yourdayiscleared'>Your day is cleared 🎉</span>
                                <span className='Nonotifications'>No notifications</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className='TermsPrivacy'>
                    <span className='Changebackgroundimage'>
                        <Link to="/terms">Terms</Link>
                        &nbsp; &middot; &nbsp;
                        <Link to="/privacy">Privacy</Link>
                    </span>
                </div>*/}
            </div>

                <div className='BackgroundImageChange' onClick={() => itemRef.current.setDisplay(true)}>
                    <span className='Changebackgroundimage'>Change background image</span>
                    <div className='image'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                    </div>
                </div>
        </div>
    )
};

export default Home;
