import React from 'react'
import './AccountSettings.css'
import ImgAsset from '../public'
import Sidebar from "./Menu"
import { IP } from '../config.js'
import { Link, useHistory } from 'react-router-dom'
import { supabase, supabaseUrl } from '../supabaseClient'
import { useState, useRef, useEffect } from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import { useCookies } from 'react-cookie'
import { getInstagramUsername, getFacebookUsername, getInstagramAccountId, getFacebookPages } from './facebookUtil'
import PushNotification from "./PushNotification"

export default function AccountSettings ({session}) {
    const [accountData, setAccountData] = useState({});
    const [avatarUrl, setAvatarUrl] = useState("");
    const [name, setName] = useState("");
    const [sharedName, setSharedName] = useState("");
    const [role, setRole] = useState("can view");
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [invitedNames, setInvitedNames] = useState([]);
    const [spreadsheet, setSpreadsheet] = useState("");
    const [clickedCopy, setClickedCopy] = useState(false);
    // Facebook
    const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
    const [instagramId, setInstagramId] = useState("");
    const [fbPageId, setFbPageId] = useState("");
    const [fbPageAccessToken, setFbPageAccessToken] = useState("");
    const [instagram, setInstagram] = useState("@username");
    const [facebook, setFacebook] = useState("@username");
    // Twitter
    const [twitter, setTwitter] = useState("@username");
    const [twitterAuthToken, setTwitterAuthToken] = useState("");
    const [twitterAuthTokenSecret, setTwitterAuthTokenSecret] = useState("");
    // Cookie stuff
    const [dark, setDark] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);

    const [overflow, setOverflow] = useState(true);
    const itemRef = useRef();
    const itemRef2 = useRef();

    let navigate = useHistory();

    const disconnect = (type) => {
      if (type === "instagram") {
        setInstagram("@username");
        setInstagramId("");
      } else if (type === "facebook") {
        setFacebook("@username");
        setFbPageId("");
        setFbPageAccessToken("");
        setInstagram("@username");
        setInstagramId("");
      } else if (type === "twitter") {
        setTwitter("@username");
        setTwitterAuthToken("");
        setTwitterAuthTokenSecret("");
      }
    }

    const updatedInvitedName = (index, field, val) => {
      setOverflow(true);
      let newArr = [...invitedNames];
      newArr[index][field] = val; //e.target.value;
      setInvitedNames(newArr);
    }

    const removeInvitedName = (index) => {
      setOverflow(true);
      let newArr = [...invitedNames];
      newArr.splice(index, 1);
      setInvitedNames(newArr);
    }

    function addInvitedName() {
      console.log(invitedNames);
      if (invitedNames.length >= 5) {
        return;
      }
      if (!sharedName) {
        return;
      }
 
      var newName = {
        "id": invitedNames.length,
        "user_email": sharedName,
        "role": role,
        "dropdown": false,
      }
 
      setInvitedNames(
        [...invitedNames, newName]
      );
    }

    const getInvitedNames = async (id_) => {
      let resp = await supabase
        .from('invitedusers')
        .select(`*`)
        .eq('account_group_id', id_)
        .order('id', { ascending: false })
      // console.log(resp);
      setInvitedNames(resp.data);
    }

    const deleteAccountGroup = async (e) => {
      setOverflow(true);

      var id_ = accountData.id;

      let resp1 = await supabase
        .from('widgets')
        .delete()
        .eq('accountgroup_id', id_);
      console.log(resp1);

      let resp2 = await supabase
        .from('invitedusers')
        .delete()
        .eq('account_group_id', id_);
      console.log(resp2);

      let { data, error } = await supabase.from('accountgroups').delete().match({ "id": id_ });
      console.log(data);
      console.log(error);
      if (error) {
        throw error;
      }

      navigate.push("/")
    }

    const saveAccountGroup = async (e) => {
      e.preventDefault();
      if (loading) {
        return;
      }

      setLoading(true);

      try {
        const update = {
          name: name,
          projectavatar: avatarUrl,
          instagram: instagram,
          facebook: facebook,
          fb_accesstoken: facebookUserAccessToken,
          instagram_id: instagramId,
          fb_pageid: fbPageId,
          fb_pageaccesstoken: fbPageAccessToken,
          twitter: twitter,
          twitter_access_token: twitterAuthToken,
          twitter_access_token_secret: twitterAuthTokenSecret
        };
        var id_ = accountData.id;
        let { data, error } = await supabase.from('accountgroups').update(update, {"returning": "representation"}).match({ "id": id_ });
        console.log(data);
        console.log(error);
        if (error) {
          throw error;
        }

        let resp2 = await supabase
          .from('accountgroups')
          .select(`*`)
          .eq('user', session.user.id)
          .order('id', { ascending: false })
          .limit(1);
        console.log(resp2);

        let resp3 = await supabase
          .from('accountgroups')
          .select(`*`)
          .eq('user', session.user.id)
          .order('id', { ascending: false })
          .limit(1);
        console.log(resp3);

        let resp = await supabase
          .from('accountgroups')
          .select(`*`)
          .eq('user', session.user.id)
          .order('id', { ascending: false })
          .limit(1);
        console.log(resp);

        for (var idx in invitedNames) {
          let r = await supabase.from('invitedusers').upsert({
              "id": invitedNames[idx].id,
              "user_email": invitedNames[idx].user_email,
              "account_group_id": resp.data[0].id,
              "role": invitedNames[idx].role
          }, {"returning": "representation"});
          console.log(r);
        }
          
        for (var idx_ in invitedNames) {
          let r = await supabase.from('invitedusers').upsert({
              "id": invitedNames[idx_].id,
              "user_email": invitedNames[idx_].user_email,
              "account_group_id": resp.data[0].id,
              "role": invitedNames[idx_].role
          }, {"returning": "representation"});
          console.log(r);
        }
      } catch (error) {
        console.log(error.message)
        alert("Error! Please fill in all inputs.")
      } finally {
        setLoading(false);
        navigate.push("/");
      }
    }

    const uploadAvatar = async (event) => {
      try {
        setUploading(true)

        if (!event.target.files || event.target.files.length === 0) {
          throw new Error('You must select an image to upload.')
        }

        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`

        let { error: uploadError } = await supabase.storage
          .from('projectavatars')
          .upload(filePath, file)

        if (uploadError) {
          throw uploadError
        }

        // onUpload(filePath)
        setAvatarUrl(supabaseUrl + "/storage/v1/object/public/projectavatars/" + filePath)
      } catch (error) {
        alert(error.message)
      } finally {
        setUploading(false)
      }
    }

    const copyLink = async () => {
      setClickedCopy(true);
      navigator.clipboard.writeText(spreadsheet);
      setTimeout(() => {
        setClickedCopy(false);
      }, 2000);
    }

    // TWITTER
    const logInToTwitter = () => {
      fetch(IP + 'twitter_auth', {
        method: 'GET',
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        console.log(resp);
        resp.json().then((ret) => {
          var interval = setInterval(() => {
            fetch(IP + 'twitter_auth_result/' + ret["token"], {
              method: 'GET',
              mode: "cors",
              credentials: "include",
            }).then((resp_) => {
              resp_.json().then((result) => {
                if ("twitter_access_token" in result) {
                  setTwitterAuthToken(result["twitter_access_token"]);
                  setTwitterAuthTokenSecret(result["twitter_access_token_secret"]);
                  setTwitter(result["username"]);
                  clearInterval(interval);
                }
              });
            });
          }, 1000);

          window.open(ret["url"], '_blank');
        })
      });
    }

    // FACEBOOK
    const logInToFB = () => {
      window.FB.login(
        (response) => {
          console.log(response);
          var fb_accesstoken = response.authResponse?.accessToken;
          setFacebookUserAccessToken(fb_accesstoken);

          getFacebookPages(fb_accesstoken).then((facebookPages) => {
            console.log(facebookPages);
            setFbPageId(facebookPages[0].id);
            setFbPageAccessToken(facebookPages[0].access_token);
            setFacebook(facebookPages[0].name);
            getInstagramAccountId(fb_accesstoken, facebookPages[0].id).then((instagramAccountId) => {
              console.log(instagramAccountId);
              setInstagramId(instagramAccountId);
              getInstagramUsername(fb_accesstoken, instagramAccountId).then((resp) => {
                  setInstagram(resp.username);
              });
            });
          });
        },
        {
          // scope: "instagram_basic,pages_show_list,instagram_content_publish,pages_manage_posts",
          config_id: "865874287999646",
        }
      );
    };

    useEffect(() => {
      var accountData = navigate.location.state.account;
        console.log(accountData);
      setName(accountData.name);
      setAvatarUrl(accountData.projectavatar);
      getInvitedNames(accountData.id);
      console.log(accountData);
      if (accountData.fb_accesstoken) {
        setFacebookUserAccessToken(accountData.fb_accesstoken);
        setFbPageId(accountData.fb_pageid);
        setFbPageAccessToken(accountData.fb_pageacesstoken);
        setInstagramId(accountData.instagram_id);
        setInstagram(accountData.instagram);
        setFacebook(accountData.facebook);
      }
      if (accountData.twitter_access_token) {
        setTwitterAuthToken(accountData.twitter_access_token);
        setTwitterAuthTokenSecret(accountData.twitter_access_token_secret);
        setTwitter(accountData.twitter);
      }
      setSpreadsheet(accountData.sheet);
      setAccountData(accountData);

      if (!("dark" in cookies)) {
        setCookie("dark", false, {
          "secure": false,
          "httpOnly": false
        });
      }
      setDark(cookies["dark"] === "true");
    }, [])

	return (
        <div className={`AccountSettings_AccountSettings ${dark ? "dark" : ""}`} style={{backgroundImage: session.user.user_metadata.background_image ? `url('${session.user.user_metadata.background_image}')` : "url('./bg.png')", overflow: !overflow ? "hidden" : "auto"}} >
{ session.user.user_metadata.background_image ?
            <div className={`${dark ? "dark" : ""} backgroundOverlay`} />
: "" }
            <Sidebar session={session} />
            <PushNotification ref={el => itemRef.current = el} title={"REMOVE USER?"} desc={"Are you sure you want to remove this user?"} action={removeInvitedName} closeAction={updatedInvitedName} />
            <PushNotification ref={el => itemRef2.current = el} title={"REMOVE ACCOUNT?"} desc={"Are you sure you want to remove this account?"} action={deleteAccountGroup} closeAction={() => setOverflow(true)} />
			<div className='Frame1'>
				<div className='Container'>
					<div className='Header'>
						<span className='HeaderTitle'>Account settings</span>
						<div className='SaveButtons'>
                            <Link to="/">
							<span className='ButtonLabel_3'>Cancel</span>
                            </Link>
                            <div className={`ButtonAction ${loading ? 'disabled' : ''}`} onClick={e => saveAccountGroup(e)}>
								<div className='save'>
									<img alt="" className='Vector_16' src = {ImgAsset.AccountSettings_Vector_16} />
									<img alt="" className='Vector_17' src = {ImgAsset.AccountSettings_Vector_17} />
									<img alt="" className='Vector_18' src = {ImgAsset.AccountSettings_Vector_18} />
								</div>
								<span className='ButtonLabel_4'>Save and close</span>
							</div>
						</div>
                        {/*<div className='AccountProfile'>
							<div className='ProfileDiv'/>
							<div className='AccountData'>
								<div className='Maskgroup_1'>
									<div className='ProfileImage_1'/>
									<img className='unsplashplsF6obTgms_1' src = {ImgAsset.Profileedit_unsplashplsF6obTgms} />
								</div>
								<div className='AccountNameInfo'>
									<span className='Name'>Sara James</span>
									<span className='JobTitle'>Project Manager</span>
									<div className='IconDiv_4'>
										<div className='MenuItemIconDiv_3'/>
										<div className='IconChevronDown'>
											<img className='Vector_19' src = {ImgAsset.AccountSettings_Vector_19} />
										</div>
									</div>
								</div>
							</div>
						</div>*/}
					</div>
					<div className='ProfileInfo'>
						<div className='LoginForm2'>
							<div className='ProfilePic'>
                                {/*<div className='DivProfile'>
									<div className='Maskgroup_2'>
										<div className='Profile'/>
										<img className='ProfilePic_1' src = {ImgAsset.Assets_1_ProfilePic} />
									</div>
									<span className='Description'>Change picture</span>
								</div>*/}
                                <div className='DivProfile'>
                                    <label htmlFor="single">
									<div className='Maskgroup_2 avatar image AddProfile'>
{ avatarUrl && avatarUrl !== "" ?
                                        <img
                                          src={avatarUrl}
                                          alt="Project avatar"
                                          className="avatar image AddProfile"
                                        />
:
                                        <img
                                          src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='0.3' strokeLinecap='round' strokeLinejoin='round' class='feather feather-plus'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E`}
                                          alt="Project avatar"
                                          style={{width: "70px", height: "70px"}}
                                        />
}
									</div>
                                    </label>
                                    <VisuallyHidden>
                                      <input
                                        type="file"
                                        id="single"
                                        accept="image/*"
                                        onChange={uploadAvatar}
                                        disabled={uploading}
                                      />
                                    </VisuallyHidden>
                                    <span className='Description'>Add picture</span>
                                </div>
							</div>
							<div className='NameDiv'>
								<div className='DivData'>
									<input className='Grouptitle' placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
									<div className='EditIcon'>
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
									</div>
								</div>
							</div>
						</div>
						<div className='DivPlan_1'>
							<div className='Text_1'>
								<div className='Payment_1'>
									<div className='users'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
									</div>
									<span className='Title_1'>Connect account</span>
								</div>
								<div className='InfoDiv'>
									<span className='InfoDiv_1'>Connect your social media accounts.</span>
								</div>
							</div>
							<div className='SocialButtons'>
								<div className='APISocialButton'>
									<div className='SocialButton_2' onClick={() => logInToTwitter()}>
										<div className='IconLabel_6'>
											<div className='twitter'>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
											</div>
											<span className='ButtonLabel_7'>Twitter</span>
										</div>
									</div>
								</div>
                                <div className='Disocnnect'>
                                    <div className={`Disconnect_2 ${twitter === "@username" ? "gray" : ""}`} onClick={() => disconnect("twitter")}>
                                        <div className='logout_2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                        </div>
                                        <span className='Description_3'>Disconnect</span>
                                    </div>
                                </div>
								<div className='Usernames'>
									<div className='SocialButton_5'>
										<div className='IconLabel_9'>
											<span className={`ButtonLabel_10 ${twitter === "@username" ? "gray" : "" }`}>{ twitter }</span>
										</div>
									</div>
								</div>
								<div className='ConnectedStatus'>
									<div className='Connected_2'>
{ twitter !== '@username' ?
                                        <>
                                            <div className='check'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                            </div>
                                            <span className='Description_3'>Connected</span>
                                        </>
:
                                        <>
                                            <div className='alertcircle_2'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                            </div>
                                            <span className='Description_3 red'>Not connected</span>
                                        </>
}
									</div>
								</div>
							</div>
						</div>
						<div className='DivPlan_2'>
							<div className='Frame1_1'>
								<div className='Text_2'>
									<div className='Payment_2'>
										<div className='globe'>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
										</div>
										<span className='Title_2'>Share to your team</span>
									</div>
									<div className='InfoDiv_2'>
										<span className='InfoDiv_3'>Anyone with the link can access the sheet</span>
									</div>
								</div>
								<div className='Users Usersinvite'>
									<div className='Invite'>
										<div className='Inputfield'>
											<input type="email" className='Text_3' placeholder="Enter emails"  value={sharedName} onChange={e => setSharedName(e.target.value)}/>
											<div className='Frame2' onClick={() => setDropdown(!dropdown)}>
												<span className='Text_4'>{ role }</span>
												<div className={`chevrondown ${dropdown ? 'rotate' : ''}`}>
													<img alt="" className='Vector_40' src = {ImgAsset.AccountSettings_Vector_40} />
												</div>
											</div>

{ dropdown ?
                                            <div className='Dropdown'>
                                                <div className='Item1' onClick={() => {setRole("Admin"); setDropdown(false);}}>
                                                    <div className='Div_3'/>
                                                    <div className='Frame3_2'>
                                                        <div className='Labels_3'>
                                                            <span className='Title_8'>Admin</span>
                                                            <span className='description_1'>Can edit, copy and change permissions</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2' onClick={() => {setRole("can edit"); setDropdown(false);}}>
                                                    <div className='Div_4'/>
                                                    <div className='Frame3_3'>
                                                        <div className='Labels_4'>
                                                            <span className='Title_9'>Can edit</span>
                                                            <span className='description_2'>Can edit and copy</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2_1' onClick={() => {setRole("can view"); setDropdown(false);}}>
                                                    <div className='Div_5'/>
                                                    <div className='Frame3_4'>
                                                        <div className='Labels_5'>
                                                            <span className='Title_10'>Can view</span>
                                                            <span className='description_3'>Can view but not edit components</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
: "" }

										</div>
										<div className='CopyLinkButton' onClick={() => addInvitedName()}>
											<span className='Text_5'>Invite</span>
										</div>
									</div>
								</div>
								<div className='Users'>
{ invitedNames.map((invitedName, idx) => (
									<div className='User' key={idx}>
										<div className='Frame1_2'>
                                            <div className='ProfileDiv_7'>
                                                <div className='DefaultProfilePic'>
                                                    <div className='Profilepic'/>
                                                    <span className='yy'>yy</span>
                                                </div>
                                            </div>
											<span className='title'>{ invitedName.user_email }</span>
										</div>
										<div className='Frame2_1' onClick={() => updatedInvitedName(idx, "dropdown", !invitedName.dropdown)}>
											<span className='Text_6'>{ invitedName.role }</span>
											<div className={`chevrondown_1 ${invitedName.dropdown ? 'rotate' : ''}`}>
												<img alt="" className='Vector_41' src = {ImgAsset.AccountSettings_Vector_41} />
											</div>
										</div>
{ invitedName.dropdown ?
                                            <div className='Dropdown DropdownInvite'>
                                                <div className='Item1' onClick={() => {
                                                    updatedInvitedName(idx, "dropdown", false);
                                                    updatedInvitedName(idx, "role", "Admin");
                                                }}>
                                                    <div className='Div_3'/>
                                                    <div className='Frame3_2'>
                                                        <div className='Labels_3'>
                                                            <span className='Title_8'>Admin</span>
                                                            <span className='description_1'>Can edit, copy and change permissions</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2' onClick={() => {
                                                    updatedInvitedName(idx, "dropdown", false);
                                                    updatedInvitedName(idx, "role", "can edit");
                                                }}>
                                                    <div className='Div_4'/>
                                                    <div className='Frame3_3'>
                                                        <div className='Labels_4'>
                                                            <span className='Title_9'>Can edit</span>
                                                            <span className='description_2'>Can edit and copy</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item2_1' onClick={() => {
                                                    updatedInvitedName(idx, "dropdown", false);
                                                    updatedInvitedName(idx, "role", "can view");
                                                }}>
                                                    <div className='Div_5'/>
                                                    <div className='Frame3_4'>
                                                        <div className='Labels_5'>
                                                            <span className='Title_10'>Can view</span>
                                                            <span className='description_3'>Can view but not edit components</span>
                                                        </div>
                                                        <div className='check_3'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Item3Remove' onClick={() => {itemRef.current.setDisplay(true);updatedInvitedName(idx, "dropdown", false);itemRef.current.setAction(idx);setOverflow(false);}}>
                                                    <div className='Div_6'/>
                                                    <div className='Frame3_5'>
                                                        <div className='Labels_6'>
                                                            <span className='Title_11'>Remove</span>
                                                            <span className='description_4'>Remove this user</span>
                                                        </div>
                                                        <div className='trash2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgba(219,13,13,1.00)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                        </div>
                                                        <div className='check_6'>
                                                            <img alt="" className='Vector_12' src = {ImgAsset.Assets_Vector_12} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
: "" }
									</div>
))}
								</div>
								<img alt="" className='Separator' src = {ImgAsset.AccountSettings_Separator} />
								<div className='CopyLink'>
									<span className='title_2'>Anyone with the link can access this sheet</span>
									<div className='CopyLinkButton_1' onClick={() => {copyLink()}}>
										<div className='Frame1_4'>
											<div className='link2'>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
											</div>
											<span className='Text_8'>Copy link</span>
{ clickedCopy ?
											<span className='Text_7_1'>Copied!</span>
: "" }
										</div>
									</div>
								</div>
							</div>
						</div>
                        {/*<div className='Delete' onClick={(e) => deleteAccountGroup(e)}>*/}
                        <div className='Delete' onClick={() => {itemRef2.current.setDisplay(true);setOverflow(false);}}>
							<div className='trash2'>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgba(219,13,13,1.00)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
							</div>
							<div className='Labels'>
								<span className='Title_3'>Delete account</span>
								<span className='description'>Only admins can delete account</span>
							</div>
							<div className='check_2'>
								<img alt="" className='Vector_49' src = {ImgAsset.AccountSettings_Vector_49} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
